import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { DischargeCalculationRow } from "./DischargeCalculationRow";
import { DischargeEntryRow } from "./DischargeEntryRow";
import { useGetStationPoolTs } from "../../hooks/useGetStationPoolTs";
import { useCalculateDischarge } from "../../hooks/useCalculateDischarge";
import { useGetStationOverflowSpillways } from "../../hooks/useOverflowSpillways";
import {
  roundValue,
  formatNumber,
} from "../../helpers/helpers";
import { Grid, Box, Paper, TextField, InputAdornment } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { SpillwayDischargeRow } from "./SpillwayDischargeRow";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  margin: 0,
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

export const DischargeCalculations = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [dischargeEstimateArray, setDischargeEstimateArray] = useState([]);
  const [poolElevation, setPoolElevation] = useState(null);
  const [totalDischarge, setTotalDischarge] = useState(null);
  const [dischargeEntryArray, setDischargeEntryArray] = useState([]);

  const {
    open,
    setOpen,
    timeseriesList,
    location,
    updateGateValues,
  } = props;
  const {
    poolTs,
    loading,
    error: poolTsError
  } = useGetStationPoolTs(location);
  const {
    overflowSpillways,
    fetchSpillwayDischargeEstimates,
    clearOverflowSpillwayEstimates,
    error: spillwayError
  } = useGetStationOverflowSpillways(location);
  const {
    fetchDischargeEstimates,
    dischargeEstimates,
    error: errorEstimates
  } = useCalculateDischarge();

  const handleCancel = () => {
    setOpen(false);
    clearDischargeEstimates();
    setTotalDischarge(null);
    clearOverflowSpillwayEstimates();
  };

  const handleAccept = () => {
    updateGateValues([...dischargeEstimateArray, ...dischargeEntryArray]);
    clearDischargeEstimates();
    setTotalDischarge(null);
    clearOverflowSpillwayEstimates();
    setOpen(false);
  };

  const handleClear = () => {
    clearGateValues();
    setTotalDischarge(null);
    clearDischargeEstimates();
    clearOverflowSpillwayEstimates();
  };

  const handleCalculate = () => {
    clearDischargeEstimates();
    fetchDischargeEstimates(dischargeEstimateArray, poolElevation);
    fetchSpillwayDischargeEstimates(poolElevation);
  }

  const updatePoolElevation = (e) => {
    setPoolElevation(e.target.value);
    clearDischargeEstimates();
    setTotalDischarge(null);
  };

  const clearDischargeEstimates = () => {
    const dataArray = JSON.parse(JSON.stringify(dischargeEstimateArray));
    dataArray.forEach((obj, idx) => {
      obj.discharge_estimate = null
    });
    setDischargeEstimateArray(dataArray);
    clearOverflowSpillwayEstimates();
    setTotalDischarge(null);
  }

  const clearGateValues = () => {
    const dataArray = JSON.parse(JSON.stringify(dischargeEstimateArray));
    dataArray.forEach((obj, idx) => {
      obj.num_value = null
    });
    setDischargeEstimateArray(dataArray)
    clearDischargeEstimates()
  }

  useEffect(() => {
    if (poolTs && !loading && poolTs.latest_value) {
      setPoolElevation(roundValue(poolTs.latest_value.num_value))
    }
  }, [poolTs, loading]);

  useEffect(() => {
    const mergeDischargeEstimates = (dischargeEstimates) => {
      const dataArray = JSON.parse(JSON.stringify(dischargeEstimateArray));
      if (dischargeEstimates.length === dataArray.length) {
        dataArray.forEach((obj, idx) => {
          obj.discharge_estimate = parseFloat(dischargeEstimates[idx].result_value).toFixed(2).toString()
        })
      }
      setDischargeEstimateArray(dataArray)
    }
    mergeDischargeEstimates(dischargeEstimates)
    // eslint-disable-next-line
  }, [dischargeEstimates]);

  useEffect(() => {
    const sumDischarges = () => {
      let sum = null;
      dischargeEstimateArray.forEach((obj) => {
        if (obj.discharge_estimate) {
          if (sum == null) {
            sum = 0
          }
          sum = sum + parseFloat(obj.discharge_estimate)
        }
      })
      overflowSpillways.forEach((obj) => {
        if (obj.discharge_estimate) {
          if (sum == null) {
            sum = 0
          }
          sum = sum + parseFloat(obj.discharge_estimate)
        }
      })
      dischargeEntryArray.forEach((obj) => {
        if (obj.num_value) {
          if (sum == null) {
            sum = 0
          }
          sum = sum + parseFloat(obj.num_value)
        }
      })
      setTotalDischarge(sum)
    }
    sumDischarges()
  }, [dischargeEstimateArray, dischargeEntryArray, overflowSpillways, setTotalDischarge]);

  useEffect(() => {
    const includeParamCodes = ["GATE", "GTCS"]
    const initializeDischargeEstimateArray = () => {
      let tmpArr = timeseriesList.filter((ts) => {
        for (let code of includeParamCodes) {
          if (ts.parameter.code.includes(code)) {
            return true
          }
        }
        return false
      }).map((ts) => {
        return {
          timeseries: ts,
          num_value: ts.latest_value ? ts.latest_value.num_value : null,
          discharge_estimate: null,
        }
      })
      // console.log(tmpArr)
      setDischargeEstimateArray(tmpArr)
    }
    initializeDischargeEstimateArray()

    const includeQParamCodes = ["RQPG"]
    const initializeDischargeEntryArray = () => {
      let tmpArr = timeseriesList.filter((ts) => {
        for (let code of includeQParamCodes) {
          if (ts.parameter.code.includes(code)) {
            return true
          }
        }
        return false
      }).map((ts) => {
        return {
          timeseries: ts,
          num_value: ts.latest_value ? ts.latest_value.num_value : null,
          discharge_estimate: null,
        }
      })
      // console.log(tmpArr)
      setDischargeEntryArray(tmpArr)
    }
    initializeDischargeEntryArray()

  }, [timeseriesList]);

  const updateGatePositions = (newEntry) => {
    // console.log(newEntry)
    const dataArray = JSON.parse(JSON.stringify(dischargeEstimateArray));
    if (
      dataArray.filter((item) => item.timeseries.id === newEntry.timeseries.id).length > 0
    ) {
      const index = dataArray.findIndex(
        (item) => item.timeseries.id === newEntry.timeseries.id
      );
      dataArray.splice(index, 1, {
        timeseries: newEntry.timeseries,
        num_value: newEntry.num_value,
        discharge_estimate: newEntry.discharge_estimate,
      }
      );
      setDischargeEstimateArray(dataArray)
    }
    clearOverflowSpillwayEstimates();
  };

  const updateDischargeEntries = (newEntry) => {
    // console.log(newEntry)
    const dataArray = JSON.parse(JSON.stringify(dischargeEntryArray));
    if (
      dataArray.filter((item) => item.timeseries.id === newEntry.timeseries.id).length > 0
    ) {
      const index = dataArray.findIndex(
        (item) => item.timeseries.id === newEntry.timeseries.id
      );
      dataArray.splice(index, 1, {
        timeseries: newEntry.timeseries,
        num_value: newEntry.num_value,
        discharge_estimate: newEntry.discharge_estimate,
      }
      );
      setDischargeEntryArray(dataArray)
    }
    // clearOverflowSpillwayEstimates();
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      maxWidth={"md"}
      open={open}
      onClose={handleCancel}
      aria-labelledby="responsive-dialog-title"

    >
      <DialogTitle id="responsive-dialog-title">
        Discharge Estimation Sheet for {location.properties.name} ({location.properties.code})
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Use this dialog to calculate the expected discharge from the gates and spillways at {location.properties.name}.
        </DialogContentText>
        <div>
          <Box className="manual-data-entry-row">
            <Grid container columns={12}>
              <Grid item sm={3} className="manual-data-entry-grid-item">
                <Item elevation={0}>Pool Elevation</Item>
              </Grid>
              <Grid item sm={3} className="manual-data-entry-grid-item">
                {poolTs &&
                  <Item elevation={0}>
                    Range: {poolTs.threshold_limits.min}{" "} - {poolTs.threshold_limits.max}{" "}
                    {poolTs.parameter.unit.code !== "NONE" ? poolTs.parameter.unit.code : ""}
                  </Item>
                }
              </Grid>
              <Grid item sm={3} className="manual-data-entry-grid-item">
                <Item elevation={0}>
                  <TextField
                    value={formatNumber(poolElevation, 2)}
                    onChange={updatePoolElevation}
                    variant="outlined"
                    size="small"
                    type="text"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          FT
                        </InputAdornment>
                      ),
                    }}
                    id="manual data entry value input"
                  />
                </Item>
              </Grid>
              <Grid item sm={3} className="manual-data-entry-grid-item">
              </Grid>
            </Grid>
          </Box>
        </div>
        <div className="manual-data-entry-rows">
          {dischargeEstimateArray.map((obj) => {
            return (
              <div key={obj.timeseries.id}>
                <DischargeCalculationRow
                  ts={obj.timeseries}
                  updateGatePositions={updateGatePositions}
                  dischargeEstimate={obj.discharge_estimate}
                  gateValue={obj.num_value}
                />
              </div>
            );
          })}
        </div>
        <div className="manual-data-entry-rows">
          {overflowSpillways.map((obj) => {
            return (
              <div key={obj.code}>
                <SpillwayDischargeRow
                  obj={obj}
                />
              </div>
            );
          })}
        </div>
        <div className="manual-data-entry-rows">
          {dischargeEntryArray.map((obj) => {
            return (
              <div key={obj.timeseries.id}>
                <DischargeEntryRow
                  ts={obj.timeseries}
                  updateGatePositions={updateDischargeEntries}
                  dischargeValue={obj.num_value}
                />
              </div>
            );
          })}
        </div>
        <div>
          <Box className="manual-data-entry-row">
            <Grid container columns={12}>
              <Grid item sm={6} className="manual-data-entry-grid-item">
                <Item elevation={0}>Total Discharge</Item>
              </Grid>
              <Grid item sm={3} className="manual-data-entry-grid-item">
              </Grid>
              <Grid item sm={3} className="manual-data-entry-grid-item">
                <Item elevation={0}>
                  <TextField
                    disabled
                    value={formatNumber(totalDischarge, 2)}
                    variant="outlined"
                    size="small"
                    type="text"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          CFS
                        </InputAdornment>
                      ),
                    }}
                  />
                </Item>
              </Grid>
            </Grid>
          </Box>
        </div>
        <Grid container columns={12}>
          <Grid item sm={3}>
            <Button autoFocus onClick={handleCalculate} color="primary">
              Calculate
            </Button>
          </Grid>
          <Grid item sm={9}>
          </Grid>
        </Grid>
        <Grid container columns={12}>
          <Grid item xs={12}>
            {errorEstimates &&
              <TextField
                disabled
                id="outlined-multiline-static"
                label="Error Message"
                fullWidth
                margin="normal"
                multiline
                value={errorEstimates}
                variant="outlined"
              />
            }
            {spillwayError &&
              <TextField
                disabled
                id="outlined-multiline-static"
                label="Error Message"
                fullWidth
                margin="normal"
                multiline
                value={spillwayError}
                variant="outlined"
              />

            }
            {poolTsError &&
              <TextField
                disabled
                id="outlined-multiline-static"
                label="Error Message"
                fullWidth
                margin="normal"
                multiline
                value={poolTsError}
                variant="outlined"
              />
            }
          </Grid>
        </Grid>

      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleClear} color="primary">
          Clear
        </Button>
        <Button onClick={handleAccept} color="primary" autoFocus>
          Use Gate Settings
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DischargeCalculations.propTypes = {
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};