import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import {
  Typography,
  Paper,
  AppBar,
  Box,
  Tab,
  Tabs,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ReactJson from "react-json-view";

import { UserContext } from "../../providers/UserProvider";
import { userActions } from "../../reducers/userReducer.js";
import { TimeseriesPlot } from "./TimeseriesPlot";
import { TimeseriesTable } from "./TimeseriesTable";
import { apiRoot } from "../../services/api";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    justifyContent: "center",
  },
  adjustTimeButton: {
    marginLeft: "auto",
  },
  tabContent: {
    justifyContent: "center",
    display: "flex-grow",
  },
  forecastBar: {
    display: "flex",
    marginBottom: theme.spacing(1),
  },
}));

const TabPanel = ({ children, value, index, ...other }) => {
  const classes = useStyles();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`timeseries-tabpanel-${index}`}
      aria-labelledby={`timeseries-tab-${index}`}
      {...other}
    >
      {value === index && <Box className={classes.paper}>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `timeseries-tab-${index}`,
    "aria-controls": `timeseries-tabpanel-${index}`,
  };
}

export const TimeseriesDetail = ({
  timeseriesMeta,
  startDatetime,
  endDatetime,
  showMeta,
}) => {
  const { state, dispatch } = useContext(UserContext);
  const { token } = state.user;

  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [hasError, setErrors] = useState(false);
  const [timeseriesValues, setTimeseriesValues] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    async function fetchData() {
      if (timeseriesMeta) {
        axios
          .get(`${apiRoot}/timeseries/${timeseriesMeta.id}/values/`, {
            params: {
              start: startDatetime && startDatetime.toISO(),
              end: endDatetime && endDatetime.toISO(),
            },
            headers: token && {
              Authorization: `Bearer ${token.access}`,
            },
          })
          .then((res) => {
            setTimeseriesValues(res.data);
          })
          .catch((err) => {
            if (err.response.status === 401) {
              window.localStorage.removeItem("afs_token");
              window.localStorage.removeItem("afs_user");
              dispatch(userActions.setToken(null));
              dispatch(userActions.setMe(null));
              setErrors("Unauthorized");
            } else if (err.response.status === 403) {
              setErrors(
                "You do not have the appropriate permissions to view / use this resource."
              );
            } else {
              console.log(err);
              setErrors(err.message);
            }
          });
      }
    }
    setTimeseriesValues(null);
    fetchData();
  }, [timeseriesMeta, startDatetime, endDatetime, token, dispatch]);

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="black"
          variant="fullWidth"
          aria-label="timeseries tabs"
        >
          <Tab label="Plot" {...a11yProps(0)} />
          <Tab label="Table" {...a11yProps(1)} />
          {showMeta === true && <Tab label="Metadata" {...a11yProps(2)} />}
        </Tabs>
      </AppBar>
      <TabPanel className={classes.tabContent} value={value} index={0}>
        {!hasError ? (
          timeseriesMeta && timeseriesValues ? (
            <TimeseriesPlot
              tsList={[{ metaInfo: timeseriesMeta, data: timeseriesValues }]}
            />
          ) : (
            <CircularProgress />
          )
        ) : (
          <Typography>Error: {JSON.stringify(hasError)}</Typography>
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {!hasError ? (
          timeseriesMeta && timeseriesValues ? (
            <TimeseriesTable
              meta={timeseriesMeta}
              data={timeseriesValues}
              setData={setTimeseriesValues}
            />
          ) : (
            <CircularProgress />
          )
        ) : (
          <Typography>Error: {JSON.stringify(hasError)}</Typography>
        )}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {!hasError ? (
          timeseriesMeta && timeseriesValues ? (
            <Paper className={classes.paper}>
              <ReactJson src={timeseriesMeta} />
            </Paper>
          ) : (
            <CircularProgress />
          )
        ) : (
          <Typography>Error: {JSON.stringify(hasError)}</Typography>
        )}
      </TabPanel>
    </div>
  );
};

TimeseriesDetail.propTypes = {
  timeseriesMeta: PropTypes.object.isRequired,
  startDatetime: PropTypes.any.isRequired,
  endDatetime: PropTypes.any.isRequired,
  showMeta: PropTypes.bool.isRequired,
};
