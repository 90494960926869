import React, { useState, useEffect, useContext } from "react";
import {
  Paper,
  makeStyles,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Typography,
} from "@material-ui/core";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import axios from "axios";
import { UserContext } from "../../providers/UserProvider";
import { apiRoot } from "../../services/api";
import { userActions } from "../../reducers/userReducer.js";
import { findExistingAlerts } from "../../helpers/helpers";
import { AlertItem } from "./AlertItem";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    // height: `calc(100vh - 80px)`,
    overflow: `auto`,
  },
  mainList: {
    // width: "100%",
  },
  nested: {
    paddingLeft: theme.spacing(3),
  },
}));

export const Alerts = () => {
  const classes = useStyles();

  const { state, dispatch } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [alerts, setAlerts] = useState(null);
  const [openBasin, setOpenBasin] = useState({});

  useEffect(() => {
    const fetchAlerts = async () => {
      setLoading(true);
      try {
        const res = await axios.get(
          apiRoot +
            `/tree_nodes/d3c4d3ca-1dcf-4412-852e-dcd9bc2e4579/forecast_alerts/`,
          {
            headers: state.user.token.access && {
              Authorization: `Bearer ${state.user.token.access}`,
            },
          }
        );
        let { data } = res;
        setAlerts(data);
        setLoading(false);
      } catch (err) {
        if (err.response.status === 401) {
          window.localStorage.removeItem("afs_token");
          window.localStorage.removeItem("afs_user");
          dispatch(userActions.setToken(null));
          dispatch(userActions.setMe(null));
          setError("Unauthorized");
          setLoading(false);
        } else if (err.response.status === 403) {
          setError(
            "You do not have the appropriate permissions to view / use this resource."
          );
          console.log(error);
          setLoading(false);
        } else {
          console.log(err);
          setError(err.message);
          setLoading(false);
        }
      }
    };
    fetchAlerts();
  }, [state.user.token.access, dispatch, error]);

  const handleClick = (item) => {
    setOpenBasin({ [item]: !openBasin[item] });
  };

  return (
    <div className={classes.root}>
      {loading && <CircularProgress />}
      {!loading && (
        // <Grid container spacing={1}>
        //   <Grid item xs={12}>
            <Paper className={classes.paper}>
              {alerts?.children.length === 0 && (
                <Typography variant="h3" color="secondary">
                  No current alerts at any location
                </Typography>
              )}
              {alerts && (
                <List className={classes.mainList}>
                  {alerts?.children.map((basin) => {
                    return (
                      <React.Fragment key={basin.id}>
                        <ListItem
                          button
                          onClick={() => handleClick(basin.name)}
                        >
                          {findExistingAlerts(basin.children) === "noalert" ? (
                            <>
                              <ListItemIcon>
                                <CheckCircleOutlinedIcon />
                              </ListItemIcon>
                              <ListItemText primary={basin.name} />
                            </>
                          ) : (
                            <>
                              <ListItemIcon>
                                <NotificationImportantIcon color="error" />
                              </ListItemIcon>
                              <ListItemText primary={basin.name} />
                              {openBasin[basin.name] ? (
                                <ExpandLess />
                              ) : (
                                <ExpandMore />
                              )}
                            </>
                          )}
                        </ListItem>

                        <Collapse
                          in={openBasin[basin.name]}
                          timeout="auto"
                          unmountOnExit
                          className={classes.nested}
                        >
                          <List
                            component="div"
                            disablePadding
                            className={classes.nested}
                          >
                            {basin.children.length > 0 &&
                              basin.children.map((scenario) => {
                                return (
                                  <>
                                    <List
                                      key={scenario.id}
                                      component="div"
                                      disablePadding
                                      className={classes.nested}
                                    >
                                      {scenario.latest_child !== null &&
                                        scenario.latest_child.alerts?.map(
                                          (alert) => {
                                            return (
                                              <Paper>
                                                <ListItem
                                                  disableGutters
                                                  dense={true}
                                                  key={alert.id}
                                                >
                                                  <AlertItem alert={alert} />
                                                </ListItem>
                                              </Paper>
                                            );
                                          }
                                        )}
                                    </List>
                                  </>
                                );
                              })}
                          </List>
                        </Collapse>
                      </React.Fragment>
                    );
                  })}
                </List>
              )}
            </Paper>
        //   </Grid>
        // </Grid>
      )}
    </div>
  );
};
