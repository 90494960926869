import React, { useState, useEffect, useContext } from "react";
import {
  FormControl,
  NativeSelect,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import axios from "axios";
import { DateTime } from "luxon";
import { UserContext } from "../../providers/UserProvider";
import { userActions } from "../../reducers/userReducer.js";
import { ForecastStyles } from "../Forecasts/ForecastStyles";

export const NodeSelector = ({
  selectedNode,
  setSelectedNode,
  url,
  params,
  defaultSelected,
}) => {
  const { state, dispatch } = useContext(UserContext);
  const { token } = state.user;

  const [nodes, setNodes] = useState([]);
  const [hasError, setErrors] = useState(false);
  const [loading, setLoading] = useState(true);
  const classes = ForecastStyles();

  const handleNodeChange = (event) => {
    setSelectedNode(nodes.find((node) => node.id === event.target.value));
  };

  useEffect(() => {
    const fetchNodes = async () => {
      setLoading(true);
      const parseDateTime = (nodeName) => {
        let dt = DateTime.fromISO(nodeName);
        if (dt.isValid) {
          return dt.toFormat("y-LL-dd HH:mm ZZZZ");
        } else {
          return nodeName;
        }
      };
      const formatNodes = (nodes) => {
        let formattedNodes = nodes.map((node) => {
          node.name = parseDateTime(node.name)
          return node
        })
        return formattedNodes
      }

      try {
        const res = await axios.get(url, {
          params: {
            name: params.name,
            tree_node: params.tree_node,
            parameter__code: params.parameter__code,
          },
          headers: token && {
            Authorization: `Bearer ${token.access}`,
          },
        });
        const { data } = res;

        const nodeIndex =
          defaultSelected === "end" ? res.data.length - 1 : defaultSelected;

        if (data.length > 0) {
          let formattedNodes = formatNodes(res.data);
          setSelectedNode(formattedNodes[nodeIndex]);
          setNodes(formattedNodes);
          setLoading(false);
        }
      } catch (err) {
        if (err.status === 401) {
          window.localStorage.removeItem("afs_token");
          window.localStorage.removeItem("afs_user");
          dispatch(userActions.setToken(null));
          dispatch(userActions.setMe(null));
          setErrors("Unauthorized");
          setLoading(false);
        } else if (err.status === 403) {
          setErrors(
            "You do not have the appropriate permissions to view / use this resource."
          );
          setLoading(false);
        } else {
          console.log(err);
          setErrors(err.message);
          setLoading(false);
        }
      }
    };

    fetchNodes();
  }, [
    setSelectedNode,
    params.name,
    params.tree_node,
    params.parameter__code,
    url,
    defaultSelected,
    dispatch,
    token,
  ]);

  return (
    <>
      {!loading ? (
        !hasError && nodes.length > 0 ? (
          <FormControl className={classes.formControl}>
            <NativeSelect
              value={selectedNode ? selectedNode.id : ""}
              onChange={handleNodeChange}
              inputProps={{
                name: "node",
                id: "node-select",
              }}
            >
              <option aria-label="None" value="" />
              {nodes.map((value, index) => {
                return (
                  <option key={index} value={value.id}>
                    {value.name}
                  </option>
                );
              })}
            </NativeSelect>
          </FormControl>
        ) : (
          <Typography>Error: {JSON.stringify(hasError)}</Typography>
        )
      ) : (
        <LinearProgress />
      )}
    </>
  );
};

NodeSelector.propTypes = {
  selectedNode: PropTypes.object,
  setSelectedNode: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  params: PropTypes.object,
  defaultSelected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
