import React, { useState, useEffect, useContext } from "react";
import { Grid, Paper, makeStyles, CircularProgress, useMediaQuery, } from "@material-ui/core";
import axios from "axios";
import { ManualDataEntriesContainer } from "./ManualDataEntriesContainer";
import { UserContext } from "../../providers/UserProvider";
import { LocationMap } from "../Mapping/LocationMap.jsx";
import { ManualDataEntryStationList } from "./ManualDataEntryStationList";
import { apiRoot } from "../../services/api";
import { userActions } from "../../reducers/userReducer.js";
import { StationMapListTabs } from "../Utils/StationMapListTabs";
import { useGetStationList } from "../../hooks/useGetStationList";
import { filterManualEntryStations } from "../../helpers/helpers";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(1)
    },
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: `calc(100vh - 80px)`,
    width: "100%",
    overflow: `auto`,
  },
  typography: {
    padding: theme.spacing(1),
  },
  popover: {
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(1),
  },
  tabPanel: {
    height: `calc(100vh - 150px)`,
  },
}));

export const ManualDataEntry = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { dispatch, state } = useContext(UserContext);
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [timeseries, setTimeseries] = useState(null);
  const { stationList, loadingList } = useGetStationList();
  const [showMap, setShowMap] = useState(true);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const filteredStationList =
    !loadingList && !error && filterManualEntryStations(stationList);

  useEffect(() => {
    const fetchTimeseriesForLocation = async () => {
      setLoading(true);
      try {
        const res = await axios.get(apiRoot + `/timeseries/`, {
          params: {
            location: selectedFeature.id,
            entry_type__code: "MAN",
            format: "json",
            enabled: true,
          },
          headers: state.user.token.access && {
            Authorization: `Bearer ${state.user.token.access}`,
          },
        });

        let { data } = res;

        setTimeseries(data);
        setLoading(false);
      } catch (err) {
        if (err.response.status === 401) {
          window.localStorage.removeItem("afs_token");
          window.localStorage.removeItem("afs_user");
          dispatch(userActions.setToken(null));
          dispatch(userActions.setMe(null));
          setError("Unauthorized");
          setLoading(false);
        } else if (err.response.status === 403) {
          setError(
            "You do not have the appropriate permissions to view / use this resource."
          );
          console.log(error);
          setLoading(false);
        } else {
          console.log(err);
          setError(err.message);
          setLoading(false);
        }
      }
    };
    selectedFeature && fetchTimeseriesForLocation();
  }, [selectedFeature, dispatch, error, state.user.token.access]);

  const handleSetSelectedFeature = (feature) => {
    setSelectedFeature(feature);
    setShowMap(false)
  }

  const mapComponent = (
    <div
      className="mde-map"
      role="img"
      aria-label="manual data entry location map"
    >
      <LocationMap
        setSelectedFeature={handleSetSelectedFeature}
        locationList={filteredStationList}
      />
    </div>
  );

  const mapComponentMobile = (
    <div
      className="mde-map-mobile"
      role="img"
      aria-label="manual data entry location map"
    >
      <LocationMap
        setSelectedFeature={handleSetSelectedFeature}
        locationList={filteredStationList}
      />
    </div>
  );

  const listComponent = (
    <ManualDataEntryStationList
      handleClick={handleSetSelectedFeature}
      stationList={stationList}
      loading={loadingList}
    />
  );

  return (
    <div className={classes.root}>
      {!isSmallScreen && (
        <Grid container spacing={1}>
          <Grid item sm={12} md={4} style={{ display: "flex" }}>
            <Paper className={classes.paper}>
              <StationMapListTabs
                mapComponent={mapComponent}
                listComponent={listComponent}
                defaultTab={"list"}
                classes={classes}
              />
            </Paper>
          </Grid>
          <Grid item sm={12} md={8} style={{ display: "flex" }}>
            <Paper className={classes.paper}>
              {loading && <CircularProgress size={70} data-testid="loader" />}
              {(!selectedFeature && !loading) || (!timeseries && !loading)
                ? "Select location to see available timeseries (manual data entry enabled)"
                : !loading && (
                  <ManualDataEntriesContainer
                    timeseriesList={timeseries}
                    feature={selectedFeature}
                  />
                )}
            </Paper>
          </Grid>
        </Grid>
      )}

      {isSmallScreen && (
        <div>
          {showMap ? (
            <StationMapListTabs
              mapComponent={mapComponentMobile}
              listComponent={listComponent}
              defaultTab={"list"}
              classes={classes}
            />
          ) : (
            <div>
              {(!selectedFeature && !loading) || (!timeseries && !loading)
                ? "Select location to see available timeseries (manual data entry enabled)"
                : !loading && (
                <ManualDataEntriesContainer
                  timeseriesList={timeseries}
                  feature={selectedFeature}
                  setShowMap={setShowMap}
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
