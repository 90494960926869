import React from "react";
import {
  List,
  ListItem,
  CircularProgress,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PropTypes from "prop-types";

import { filterManualEntryStations } from "../../helpers/helpers";

export const ManualDataEntryStationList = ({
  handleClick,
  stationList,
  loading,
}) => {
  return (
    <>
      {loading && <CircularProgress />}
      <List dense>
        {stationList &&
          !loading &&
          filterManualEntryStations(stationList).map((station) => {
            return (
              <ListItem
                disableGutters
                key={station.id}
                onClick={() => handleClick(station)}
                button
              >
                <ListItemIcon>
                  <LocationOnIcon />
                </ListItemIcon>
                <ListItemText
                  primary={station.properties.code}
                  secondary={station.properties.name}
                />
              </ListItem>
            );
          })}
      </List>
    </>
  );
};

ManualDataEntryStationList.propTypes = {
  handleClick: PropTypes.func,
};
