export const linksList = [
  {
    url: "https://www.wunderground.com/intellicast",
    text: "Intellicast Radar Loop Image",
  },

  {
    url: "https://www.wpc.ncep.noaa.gov/qpf/24hrqpfall.html",
    text: "Hydrometeorogical Prediction Center:  Days 1 - 7 QPF Forecasts",
  },

  {
    url: "https://water.noaa.gov/wfo/gyx",
    text: "National Water Prediction Service (NWPS)",
  },

  {
    url: "https://www.weather.gov/nerfc/snow",
    text: "National Weather Service:  NERFC Snow Page",
  },

  {
    url: "https://www.weather.gov/nerfc/",
    text: "National Weather Service:  Northeast River Forecast Center",
  },

  {
    url: "https://www.nae.usace.army.mil/",
    text: "United States Army Corps of Engineers - New England District",
  },

  {
    url: "https://www.usgs.gov/mission-areas/water-resources",
    text: "US Geological Survey (USGS) -- Water",
  },

  {
    url: "https://waterdata.usgs.gov/nh/nwis/current/?type=flow",
    text: "USGS Real-Time Data for New Hampshire:  Streamflow",
  },
];
