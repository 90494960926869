import React, { useState, useContext, useEffect } from "react";
import {
  Typography,
  Grid,
  Box,
  Paper,
  Button,
  Toolbar,
  AppBar,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import LuxonUtils from "@date-io/luxon";
import { apiRoot } from "../../services/api";
import { postData } from "../../requests/request";
import { UserContext } from "../../providers/UserProvider";
import { userActions } from "../../reducers/userReducer.js";
import { EditableDataRow } from "./EditableDataRow";
import { getRoundedDate } from "../../helpers/helpers";
import { ForecastStyles } from "../Forecasts/ForecastStyles";
import "../ManualDataEntry/ManualDataEntry.css";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.primary,
}));

export const ModelStatesContainer = ({ timeseriesList, feature }) => {
  const { state, dispatch } = useContext(UserContext);
  const [userInputData, setUserInputData] = useState([]);
  const [clearDataFlag, setClearDataFlag] = useState(false);
  const [errorFlag, setErrorFlag] = useState(false);
  const [batchDate, setBatchDate] = useState(null);
  const classes = ForecastStyles();

  useEffect(() => {
    setUserInputData([]);
    setBatchDate(null);
    setClearDataFlag(false);
  }, [timeseriesList]);

  const updateDataEntryArray = (newEntry) => {
    const dataArray = [...userInputData];

    if (
      dataArray.filter((item) => item.timeseries === newEntry.timeseries)
        .length > 0
    ) {
      const index = dataArray.findIndex(
        (item) => item.timeseries === newEntry.timeseries
      );

      dataArray.splice(index, 1);
      dataArray.push({
        timeseries: newEntry.timeseries,
        datetime: getRoundedDate(1, newEntry.datetime),
        num_value: newEntry.num_value,
      });

      setUserInputData(dataArray);
    } else {
      dataArray.push({
        timeseries: newEntry.timeseries,
        datetime: getRoundedDate(1, newEntry.datetime),
        num_value: newEntry.num_value,
      });

      setUserInputData(dataArray);
    }
  };

  const updating = () => {
    setErrorFlag(false);
    clearDataFlag && setClearDataFlag(false);
  };

  const handleSave = () => {
    // to do: better error handling from user perspective
    const path = `${apiRoot}/timeseries_values/`;
    const payload = userInputData;
    const config = {
      headers: state.user.token.access && {
        Authorization: `Bearer ${state.user.token.access}`,
      },
    };
    postData(path, payload, config).then((response) => {
      if (response.status === 200 || 201) {
        console.log(response);
        setUserInputData([]);
        setBatchDate(null);
        setClearDataFlag(true);
        setErrorFlag(false);
      } else {
        if (response.status === 401) {
          dispatch(userActions.setToken(null));
          dispatch(userActions.setMe(null));
          setUserInputData([]);
          setBatchDate(null);
          setClearDataFlag(true);
        } else if (response.status >= 400) {
          console.log(response);
        } else {
          console.log(response);
        }
      }
    });
  };

  const handleClear = () => {
    setBatchDate(null);
    setErrorFlag(false);
    setClearDataFlag(true);
    setUserInputData([]);
  };

  const errorFlagFunction = () => {
    setErrorFlag(true);
  };
  return (
    <>
      <AppBar
        className={classes.modelStatesBar}
        color="default"
        position="static"
      >
        <Toolbar>
          <Typography variant="h5">
            {feature.properties.name} ({feature.properties.code}) Model States
          </Typography>
        </Toolbar>
      </AppBar>

      <div className="manual-data-entry-table">
        <Box className="manual-data-entry-table-header">
          <Grid container spacing={1} columns={12}>
            <Grid item lg={3} sm={3} xs={12}>
              <Item>Name</Item>
            </Grid>
            <Grid item lg={3} sm={3} xs={12}>
              <Item>Limits</Item>
            </Grid>
            <Grid item lg={3} sm={6} xs={12}>
              <Item>New Date</Item>
            </Grid>
            <Grid item lg={3} sm={6} xs={12}>
              <Item>New Value</Item>
            </Grid>
          </Grid>
        </Box>
        <div className="manual-data-entry-rows">
          {timeseriesList.map((singleTS) => {
            return (
              <div key={singleTS.id}>
                <EditableDataRow
                  singleTS={singleTS}
                  updateDataEntryArray={updateDataEntryArray}
                  batchDate={batchDate}
                  clearDataFlag={clearDataFlag}
                  updating={updating}
                  errorFlag={errorFlagFunction}
                  optionalColumns={["limits"]}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="manual-data-entry-batch-actions">
        <MuiPickersUtilsProvider utils={LuxonUtils}>
          <KeyboardDateTimePicker
            label="Set All Date/Times"
            format="MM/dd/yyyy HH:mm"
            value={batchDate}
            onChange={(date) => setBatchDate(date)}
            placeholder="01/01/2021 00:00"
            views={["date", "year", "month", "hours"]}
            ampm={false}
            inputVariant="outlined"
            size="small"
          />
        </MuiPickersUtilsProvider>
        <Button
          disabled={userInputData.length < 1 || errorFlag}
          onClick={handleSave}
          variant="contained"
          color="primary"
        >
          Save
        </Button>
        <Button
          size="small"
          color="primary"
          onClick={handleClear}
          variant="contained"
        >
          Clear
        </Button>
      </div>
    </>
  );
};

ModelStatesContainer.propTypes = {
  timeseriesList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      parameter: PropTypes.object,
      threshold_limits: PropTypes.object,
      latest_value: PropTypes.object,
    })
  ),
  feature: PropTypes.object,
};
