import React, { useState, useEffect } from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import LuxonUtils from "@date-io/luxon";
import PropTypes from "prop-types";
import { Grid, Box, Paper, TextField, InputAdornment } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import {
  // dateFormatter,
  getRoundedDate,
  // formatNumber,
} from "../../helpers/helpers";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

export const EditableDataRow = ({
  singleTS,
  updateDataEntryArray,
  clearDataFlag,
  updating,
  errorFlag,
  optionalColumns,
  batchDate,
  defaultValue,
}) => {
  const [userInputDate, setUserInputDate] = useState(null);
  const [userInputValue, setUserInputValue] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const validate = () => {
    setErrorMessage("");
    let isValid = true;

    userInputValue !== "" &&
      userInputValue !== null &&
      (isValid =
        (singleTS.threshold_limits.min <= parseFloat(userInputValue) &&
          parseFloat(userInputValue) <= singleTS.threshold_limits.max) ||
          singleTS.threshold_limits.min == null ||
          singleTS.threshold_limits.max == null
          ? true
          : false);
    !isValid && setErrorMessage("Choose value within range");
    !isValid && errorFlag();
    return isValid;
  };

  const updateUserInputDate = (date) => {
    setUserInputDate(date);
    updating();
  };

  const updateValue = (e) => {
    setErrorMessage("");
    setUserInputValue(e.target.value);
    updating();
  };

  useEffect(() => {
    userInputValue !== null &&
      userInputDate !== null &&
      updateDataEntryArray({
        timeseries: singleTS.id,
        datetime: getRoundedDate(60, userInputDate),
        num_value: parseFloat(userInputValue),
      });
    // eslint-disable-next-line
  }, [userInputValue, userInputDate]);

  useEffect(() => {
    if (defaultValue) {
      setUserInputValue(defaultValue)
    }
    // eslint-disable-next-line
  }, [defaultValue]);

  useEffect(() => {
    if (clearDataFlag === true) {
      setUserInputDate(null);
      setUserInputValue(null);
      setErrorMessage("");
    }
  }, [clearDataFlag]);

  useEffect(() => {
    if (batchDate) {
      setUserInputDate(batchDate);
    } else {
      setUserInputDate(null);
    }
    // eslint-disable-next-line
  }, [batchDate]);

  return (
    <>
      <Box className="manual-data-entry-row">
        <Grid container columns={12}>
          <Grid item lg={3} sm={3} xs={12} className="manual-data-entry-grid-item">
            <Item elevation={0}>{singleTS.name}</Item>
          </Grid>
          {optionalColumns.includes("limits") ? (
            <Grid item lg={3} sm={3} xs={12} className="manual-data-entry-grid-item">
              <Item elevation={0}>
                Range: {singleTS.threshold_limits.min}{" - "}{singleTS.threshold_limits.max}{" "}
                {singleTS.parameter.unit.code !== "NONE" ? singleTS.parameter.unit.code : ""}
              </Item>
            </Grid>
          ) : null}
          {/* {optionalColumns.includes("latest") ? (
            <Grid item lg={3} sm={4} xs={12} className="manual-data-entry-grid-item">
              <Item elevation={0}>
                Datetime: {singleTS.latest_value
                  ? dateFormatter(singleTS.latest_value.datetime)
                  : "Null"}
                <br></br>
                Value: {singleTS.latest_value
                  ? formatNumber(singleTS.latest_value.num_value, 2)
                  : "Null"}{" "}
                {singleTS.parameter.unit.code !== "NONE"
                  ? singleTS.parameter.unit.code
                  : ""}
              </Item>
            </Grid>
          ) : null} */}
          <Grid item lg={3} sm={6} xs={12} className="manual-data-entry-grid-item">
            <Item elevation={0}>
              <MuiPickersUtilsProvider utils={LuxonUtils}>
                <KeyboardDateTimePicker
                fullWidth
                  label="Date/Time"
                  format="MM/dd/yyyy HH:mm"
                  value={userInputDate}
                  onChange={(date) => updateUserInputDate(date)}
                  placeholder="01/01/2021 00:00"
                  views={["date", "year", "month", "hours"]}
                  inputVariant="outlined"
                  size="small"
                  ampm={false}
                  id="date-time picker"
                />
              </MuiPickersUtilsProvider>
            </Item>
          </Grid>
          <Grid item lg={3} sm={6} xs={12} className="manual-data-entry-grid-item">
            <Item elevation={0}>
              <TextField
              fullWidth
                error={errorMessage ? true : false}
                value={userInputValue ? userInputValue : ""}
                onChange={updateValue}
                onBlur={validate}
                placeholder="Value"
                variant="outlined"
                size="small"
                type="text"
                InputProps={{
                  pattern: "/^-?[0-9]d*(.d+)?$/",
                  endAdornment: (
                    <InputAdornment position="end">
                      {singleTS.parameter.unit.code !== "NONE"
                        ? singleTS.parameter.unit.code
                        : ""}
                    </InputAdornment>
                  ),
                }}
                helperText={errorMessage}
                id="manual data entry value input"
              />
            </Item>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

EditableDataRow.propTypes = {
  singleTS: PropTypes.shape({
    id: PropTypes.string,
    code: PropTypes.string,
    entry_type: PropTypes.object,
    interval: PropTypes.number,
    latest_value: PropTypes.object,
    location: PropTypes.object,
    name: PropTypes.string,
    parameter: PropTypes.object,
    properties: PropTypes.object,
    threshold_limits: PropTypes.object,
    tree_node: PropTypes.object,
  }),
  updateDataEntryArray: PropTypes.func,
  batchDate: PropTypes.object,
  clearDataFlag: PropTypes.bool,
  updating: PropTypes.func,
  errorFlag: PropTypes.func,
  optionalColumns: PropTypes.arrayOf(PropTypes.string),
  defaultValue: PropTypes.number,
};
