import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  CircularProgress,
} from "@material-ui/core";
import LinkIcon from "@material-ui/icons/Link";
import { useGetSnowDataList } from "../../hooks/useGetSnowDataList"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  secondaryBar: {
    display: "flex",
    marginBottom: theme.spacing(1),
  },
  paper: {
    display: "flex-grow",
  },
}));

export const SnowData = () => {
  const classes = useStyles();
  const { snowDataFileList, loadingList, error} = useGetSnowDataList();
  return (
    <div className={classes.root}>
      <AppBar
        className={classes.secondaryBar}
        position="static"
        color="default"
      >
        <Toolbar>
          <Typography variant="h6">Snow Data</Typography>
        </Toolbar>
      </AppBar>

      <Paper className={classes.paper}>
      {loadingList && <CircularProgress />}
      {error && <Typography>Error: {JSON.stringify(error)}</Typography>}
        <List>
          {snowDataFileList.map((file, index) => {
            return (
              <ListItem
                button
                component="a"
                target="_blank"
                href={file.file}
                key={index}
              >
                <ListItemIcon>
                  <LinkIcon />
                </ListItemIcon>
                <ListItemText primary={file.name} secondary={file.file} style={{ color: "blue" }}/>
              </ListItem>
            );
          })}
        </List>
      </Paper>
    </div>
  );
};
