import "leaflet/dist/leaflet.css";
import "../../css/Map.css";
import React from "react";
import { GeoJSON } from "react-leaflet";

export const SubbasinGeoJSON = (props) => {
  const { geoJsonRef, features, setSelectedFeature, selectable } = props;
  const highlight = {
    fillColor: "red",
  };
  const style = {
    color: "blue",
    weight: 2,
    opacity: 1,
  };
  const onEachSubbasin = (feature, layer) => {
    layer.on({
      click: () => {
        if (selectable) {
          geoJsonRef.current.resetStyle();
          setSelectedFeature(feature);
          layer.setStyle(highlight);
        }
      },
      mouseover: () => {
        layer.bindTooltip(feature.properties.name, { direction: "center" });
        layer.openTooltip();
      },
      mouseout: () => {
        layer.closeTooltip();
      },
    });
  };

  return (
    <GeoJSON
      ref={geoJsonRef}
      key={features}
      data={features}
      style={style}
      onEachFeature={onEachSubbasin}
    />
  );
};
