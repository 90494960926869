import React, { useState, useEffect, useContext } from "react";
import { DateTime } from "luxon";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, AppBar, Toolbar, IconButton } from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { TimeseriesDetail } from "./TimeseriesDetail";
import { userActions } from "../../reducers/userReducer.js";
import { apiRoot } from "../../services/api";
import { UserContext } from "../../providers/UserProvider";
import DatetimeSelector from "../Utils/DateTimeSelector";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    justifyContent: "center",
  },
  adjustTimeButton: {
    marginLeft: "auto",
  },
  tabContent: {
    justifyContent: "center",
    display: "flex-grow",
  },
  forecastBar: {
    display: "flex",
    marginBottom: theme.spacing(1),
  },
  text: {
    padding: theme.spacing(2),
  },
}));

export const TimeseriesDetailComp = (props) => {
  const { state, dispatch } = useContext(UserContext);
  const { token } = state.user;
  const classes = useStyles();
  const [hasError, setErrors] = useState(false);
  const [open, setOpen] = useState(false);
  const [startDatetime, setStartDatetime] = useState(
    DateTime.now().minus({ days: 30 })
  );
  const [endDatetime, setEndDatetime] = useState(
    DateTime.now().plus({ days: 30 })
  );
  const [timeseriesMeta, setTimeseriesMeta] = useState(null);
  const { timeseriesId } = props;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const fetchTimeseriesData = async () => {
      setTimeseriesMeta(null);
      if (timeseriesId) {
        try {
          const res = await axios.get(
            apiRoot + "/timeseries/" + timeseriesId + "/",
            {
              headers: token && {
                Authorization: `Bearer ${token.access}`,
              },
            }
          );

          setTimeseriesMeta(res.data);
        } catch (err) {
          if (err.response.status === 401) {
            window.localStorage.removeItem("afs_token");
            window.localStorage.removeItem("afs_user");
            dispatch(userActions.setToken(null));
            dispatch(userActions.setMe(null));
            setErrors("Unauthorized");
          } else if (err.response.status === 403) {
            setErrors(
              "You do not have the appropriate permissions to view / use this resource."
            );
          } else {
            console.log(err);
            setErrors(err.message);
          }
        }
      }
    };

    fetchTimeseriesData();
  }, [timeseriesId, token, dispatch]);

  return (
    <>
      <AppBar className={classes.forecastBar} position="static" color="default">
        <Toolbar>
          <Typography variant="h6">
            {timeseriesMeta ? timeseriesMeta.code : "Plot"}
          </Typography>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpen}
            color="primary"
            className={classes.adjustTimeButton}
          >
            <AccessTimeIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {!hasError ? (
        timeseriesMeta ? (
          <>
            <TimeseriesDetail
              timeseriesMeta={timeseriesMeta}
              startDatetime={startDatetime}
              endDatetime={endDatetime}
              showMeta={true}
            />
            <DatetimeSelector
              open={open}
              setOpen={setOpen}
              handleClose={handleClose}
              startDatetime={startDatetime}
              setStartDatetime={setStartDatetime}
              endDatetime={endDatetime}
              setEndDatetime={setEndDatetime}
            />
          </>
        ) : (
          <Typography className={classes.text}>
            Select a timeseries to view data.
          </Typography>
        )
      ) : (
        <Typography>Error: {JSON.stringify(hasError)}</Typography>
      )}
    </>
  );
};
