import React, { useState, useContext, useEffect, useCallback } from "react";
import {
  AppBar,
  Typography,
  Grid,
  Box,
  Paper,
  Button,
  ButtonGroup,
  useMediaQuery,
  Toolbar,
  IconButton,
} from "@material-ui/core";
import { styled, makeStyles, useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import LuxonUtils from "@date-io/luxon";
import { apiRoot } from "../../services/api";
import { postData } from "../../requests/request";
import { UserContext } from "../../providers/UserProvider";
import { userActions } from "../../reducers/userReducer.js";
import { EditableDataRow } from "./EditableDataRow";
import { getRoundedDate } from "../../helpers/helpers";
import "./ManualDataEntry.css";
import "../DischargeCalculations/DischargeCalculations"
import { DischargeCalculations } from "../DischargeCalculations/DischargeCalculations";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  locationHeader: {
    flexGrow: 1,
  },
  backButton: {
    marginRight: "auto"
  },
  toolbar: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0
    },
  },
  datumText: {
    padding: theme.spacing(1),
    textAlign: "center",
  }
}));

export const ManualDataEntriesContainer = ({ timeseriesList, feature, setShowMap }) => {
  const { state, dispatch } = useContext(UserContext);
  const [userInputData, setUserInputData] = useState([]);
  const [clearDataFlag, setClearDataFlag] = useState(false);
  const [errorFlag, setErrorFlag] = useState(false);
  const [batchDate, setBatchDate] = useState(null);
  const [showDischargeCalcs, setShowDischargeCalcs] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const initializeUserInputData = useCallback(() => {
    let tmpArr = timeseriesList.map((ts) => {
      return {
        timeseries: ts,
        datetime: null,
        num_value: null
      }
    });
    setUserInputData(tmpArr);
  }, [timeseriesList])

  const updateGateValues = (dischargeEstimateArray) => {
    const dataArray = JSON.parse(JSON.stringify(userInputData));
    dischargeEstimateArray.forEach((obj) => {
      const index = dataArray.findIndex((item) => {
        return item.timeseries.id === obj.timeseries.id
      });
      if (!isNaN(obj.num_value)) {
        if (index > -1) {
          let newObj = {
            timeseries: dataArray[index].timeseries,
            datetime: dataArray[index].datetime,
            num_value: parseFloat(obj.num_value),
          }
          dataArray.splice(index, 1, newObj);
        } else {
          let newObj = {
            timeseries: obj.timeseries,
            datetime: null,
            num_value: parseFloat(obj.num_value),
          }
          dataArray.push(newObj)
        }
      }
    })
    setUserInputData(dataArray);
  };

  const updateDataEntryArrayValue = (newEntry) => {
    const dataArray = JSON.parse(JSON.stringify(userInputData));
    const index = dataArray.findIndex(
      (item) => item.timeseries.id === newEntry.timeseries.id
    );
    if (index > -1) {
      const oldEntry = JSON.parse(JSON.stringify(dataArray[index]));
      dataArray.splice(index, 1, {
        timeseries: newEntry.timeseries,
        datetime: oldEntry.datetime ? getRoundedDate(1, oldEntry.datetime) : null,
        num_value: newEntry.num_value ? newEntry.num_value : null,
      });
    }
    setUserInputData(dataArray);
  };

  const updateDataEntryArrayDate = (newEntry) => {
    const dataArray = JSON.parse(JSON.stringify(userInputData));
    const index = dataArray.findIndex(
      (item) => item.timeseries.id === newEntry.timeseries.id
    );
    if (index > -1) {
      const oldEntry = JSON.parse(JSON.stringify(dataArray[index]));
      dataArray.splice(index, 1, {
        timeseries: newEntry.timeseries,
        datetime: newEntry.datetime ? getRoundedDate(1, newEntry.datetime) : null,
        num_value: oldEntry.num_value ? oldEntry.num_value : null,
      });
    }
    setUserInputData(dataArray);
  };

  const updating = () => {
    setErrorFlag(false);
    clearDataFlag && setClearDataFlag(false);
  };

  const handleSave = () => {
    // to do: better error handling from user perspective
    const path = `${apiRoot}/timeseries_values/`;
    // format payload for service
    const payload = userInputData.filter((obj) => {
      return (obj.num_value !== null) && (obj.datetime !== null)
    }).map((obj) => {
      return {
        timeseries: obj.timeseries.id,
        num_value: obj.num_value,
        datetime: obj.datetime
      }
    });
    const config = {
      headers: state.user.token.access && {
        Authorization: `Bearer ${state.user.token.access}`,
      },
    };
    const response = postData(path, payload, config);
    if (response.status === 200 || 201) {
      initializeUserInputData();
      setBatchDate(null);
      setClearDataFlag(true);
      setErrorFlag(false);
    } else {
      if (response.status === 401) {
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
        initializeUserInputData();
        setBatchDate(null);
        setClearDataFlag(true);
      } else if (response.status >= 400) {
        console.log(response);
      } else {
        console.log(response);
      }
    }
  };

  const handleClear = () => {
    setErrorFlag(false);
    setClearDataFlag(true);
    setBatchDate(null);
    initializeUserInputData();
    setSaveDisabled(true);
  };

  const handleOpenDischargeCalcs = () => {
    setShowDischargeCalcs(true)
  }

  const errorFlagFunction = () => {
    setErrorFlag(true);
  };

  const getDatumText = (feature) => {
    let fields = feature.properties.fields;
    let datumTextObj = fields?.find(
      (element) => element.attribute_name.code === "DATUM_TEXT"
    );
    return datumTextObj ? datumTextObj.char_value : "";
  };

  useEffect(() => {
    const updateAllDates = (batchDate) => {
      if (batchDate) {
        const dataArray = JSON.parse(JSON.stringify(userInputData));
        dataArray.forEach((obj) => {
          obj.datetime = batchDate
        })
        setUserInputData(dataArray);
      }
    }
    updateAllDates(batchDate);
    // eslint-disable-next-line
  }, [batchDate]);

  useEffect(() => {
    const disableSave = () => {
      setSaveDisabled(false);
      if (userInputData.length === 0) {
        setSaveDisabled(true);
      }

      let count = 0;
      userInputData.forEach((obj) => {
        if (obj.num_value !== null && obj.datetime !== null) {
            count += 1
          }
      })
      if (count === 0){
        setSaveDisabled(true);
      }

      userInputData.forEach((obj) => {
        if (obj.num_value !== null) {
          if (obj.datetime == null) {
            setSaveDisabled(true);
          }
        }
      })
      if (errorFlag) {
        setSaveDisabled(true);
      }
    }
    disableSave()
  }, [userInputData, setSaveDisabled, errorFlag]);

  useEffect(() => {
    initializeUserInputData();
    setClearDataFlag(false);
  }, [timeseriesList, initializeUserInputData]);

  const handleSetShowMap = () => {
    setShowMap(true);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default" className={classes.toolbar}>
        <Toolbar className={classes.toolbar}>
          {isSmallScreen &&
            (
              <IconButton
                aria-label="back to map"
                aria-controls="map-appbar"
                onClick={handleSetShowMap}
                color="primary"
                className={classes.backButton}
              >
                <ChevronLeftIcon />
              </IconButton>
            )}

          <Typography className={classes.locationHeader} variant={"h6"}>
            {feature.properties.name} ({feature.properties.code})
          </Typography>
        </Toolbar>
      </AppBar>
      <>
        <Typography className={classes.datumText}>{getDatumText(feature)}</Typography>
        <div className="manual-data-entry-table">
          {!isSmallScreen &&
            (
              <Box className="manual-data-entry-table-header">
                <Grid container spacing={1} columns={12}>
                  <Grid item lg={2} sm={4} xs={12}>
                    <Item>Name</Item>
                  </Grid>
                  <Grid item lg={2} sm={4} xs={12}>
                    <Item>Limits</Item>
                  </Grid>
                  <Grid item lg={3} sm={4} xs={12}>
                    <Item>Latest Value</Item>
                  </Grid>
                  <Grid item lg={3} sm={6} xs={12}>
                    <Item>New Date</Item>
                  </Grid>
                  <Grid item lg={2} sm={6} xs={12}>
                    <Item>New Value</Item>
                  </Grid>
                </Grid>
              </Box>
            )}
          <div className="manual-data-entry-rows">
            {userInputData.map((obj) => {
              return (
                <div key={obj.timeseries.id}>
                  <EditableDataRow
                    ts={obj.timeseries}
                    updateDataEntryArrayDate={updateDataEntryArrayDate}
                    updateDataEntryArrayValue={updateDataEntryArrayValue}
                    updating={updating}
                    errorFlag={errorFlagFunction}
                    date={obj.datetime}
                    value={obj.num_value}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div className="manual-data-entry-table">
          <Box className="manual-data-entry-table-header">
            <Grid container spacing={1} columns={12}>
              <Grid item lg={3} xs={12} >
                <MuiPickersUtilsProvider utils={LuxonUtils}>
                  <KeyboardDateTimePicker
                    label="Set All Date/Times"
                    format="MM/dd/yyyy HH:mm"
                    value={batchDate}
                    onChange={(date) => setBatchDate(date)}
                    placeholder="01/01/2021 00:00"
                    views={["date", "year", "month", "hours"]}
                    ampm={false}
                    id="set all date-times with date-time picker"
                    inputVariant="outlined"
                    size="small"
                    fullWidth
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item lg={9} xs={12}>
                <ButtonGroup color="primary">
                  <Button
                    disabled={saveDisabled}
                    onClick={handleSave}
                    color="primary"
                    size="medium"
                  >
                    Save
                  </Button>
                  <Button color="primary" onClick={handleClear} size="medium">
                    Clear
                  </Button>
                  <Button color="primary" onClick={handleOpenDischargeCalcs} size="medium" fullWidth>
                    Show Discharge Calculations
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          </Box>
        </div>
        <DischargeCalculations
          open={showDischargeCalcs}
          setOpen={setShowDischargeCalcs}
          timeseriesList={timeseriesList}
          location={feature}
          updateGateValues={updateGateValues}
        />
      </>
    </div>
  );
};

ManualDataEntriesContainer.propTypes = {
  timeseriesList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      parameter: PropTypes.object,
      threshold_limits: PropTypes.object,
      latest_value: PropTypes.object,
    })
  ),
  feature: PropTypes.object,
};
