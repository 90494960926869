import React from "react";
import PropTypes from "prop-types";
import { Grid, Box, Paper, TextField, InputAdornment } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import {
  formatNumber,
} from "../../helpers/helpers";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  margin: 0,
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

export const SpillwayDischargeRow = ({
  obj
}) => {

  return (
    <>
      <Box className="manual-data-entry-row">
        <Grid container columns={12}>
          <Grid item sm={4} className="manual-data-entry-grid-item">
            <Item elevation={0}>{obj.name}</Item>
          </Grid>
          <Grid item sm={2} className="manual-data-entry-grid-item">
          </Grid>
          <Grid item sm={3} className="manual-data-entry-grid-item">
          </Grid>
          <Grid item sm={3} className="manual-data-entry-grid-item">
            <Item elevation={0}>
              <TextField
                disabled
                value={formatNumber(obj.discharge_estimate, 2)}
                variant="outlined"
                size="small"
                type="text"
                InputProps={{
                  pattern: "/^-?[0-9]d*(.d+)?$/",
                  endAdornment: (
                    <InputAdornment position="end">
                      CFS
                    </InputAdornment>
                  ),
                }}
                id="manual data entry value input"
              />
            </Item>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

SpillwayDischargeRow.propTypes = {
  obj: PropTypes.object,
};
