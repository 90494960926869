import React, { useState } from "react";
import {
  IconButton,
  Popover,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import { ForecastStyles } from "../Forecasts/ForecastStyles";

export const ModelStatesSettings = ({
  handleMapCheck,
  handleModelStatesCheck,
  mapCheck,
  modelStatesCheck,
}) => {
  const classes = ForecastStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <IconButton
        aria-label="map and model states display toggle"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleClick}
        color="primary"
      >
        <SettingsIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        className={classes.popover}
      >
        <div className={classes.popover}>
          <FormControlLabel
            control={
              <Switch
                checked={mapCheck}
                onChange={handleMapCheck}
                color="primary"
              />
            }
            label="List/Map"
          />
          <FormControlLabel
            control={<Switch checked={true} color="primary" disabled />}
            label="Plots"
          />
          <FormControlLabel
            control={
              <Switch
                checked={modelStatesCheck}
                onChange={handleModelStatesCheck}
                color="primary"
              />
            }
            label="Model States"
          />
        </div>
      </Popover>
    </div>
  );
};
