import React, { useState } from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import LuxonUtils from "@date-io/luxon";
import PropTypes from "prop-types";
import { Grid, Box, Paper, TextField, InputAdornment } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import {
  dateFormatter,
  getRoundedDate,
  formatNumber,
} from "../../helpers/helpers";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

export const EditableDataRow = ({
  ts,
  updateDataEntryArrayDate,
  updateDataEntryArrayValue,
  updating,
  errorFlag,
  date,
  value,
}) => {
  const [errorMessage, setErrorMessage] = useState("");

  const validate = (value) => {
    setErrorMessage("");
    let isValid = true;

    value !== "" &&
      value !== null &&
      (isValid =
        (ts.threshold_limits.min <= parseFloat(value) &&
          parseFloat(value) <= ts.threshold_limits.max) ||
          ts.threshold_limits.min == null ||
          ts.threshold_limits.max == null
          ? true
          : false);
    !isValid && setErrorMessage("Choose value within range");
    !isValid && errorFlag();
    return isValid;
  };

  const updateUserInputDate = (date) => {
    updateDataEntryArrayDate({
      timeseries: ts,
      datetime: getRoundedDate(60, date),
    });
    updating();
  };

  const updateValue = (e) => {
    setErrorMessage("");
    const value = e.target.value;
    const regex = /^-?[0-9.]*$/g;
    if (value.match(regex) && value.match(regex).length === 1) {
      updateDataEntryArrayValue({
        timeseries: ts,
        num_value: value,
      });
      updating();
    }
    
  };

  const handleOnBlur = (e) => {
    setErrorMessage("");
    const value = e.target.value;
    validate(value)
  };

  return (
    <>
      <Box className="manual-data-entry-row">
        <Grid container columns={12}>
          <Grid item lg={2} sm={4} xs={12} className="manual-data-entry-grid-item">
            <Item elevation={0}>{ts.name}</Item>
          </Grid>
          <Grid item lg={2} sm={4} xs={12} className="manual-data-entry-grid-item">
            <Item elevation={0}>
              Range: {ts.threshold_limits.min}{" - "}{ts.threshold_limits.max}{" "}
              {ts.parameter.unit.code !== "NONE" ? ts.parameter.unit.code : ""}
            </Item>
          </Grid>
          <Grid item lg={3} sm={4} xs={12} className="manual-data-entry-grid-item">
            <Item elevation={0}>
              Datetime: {ts.latest_value
                ? dateFormatter(ts.latest_value.datetime)
                : "Null"}
              <br></br>
              Value: {ts.latest_value
                ? formatNumber(ts.latest_value.num_value, 2)
                : "Null"}{" "}
              {ts.parameter.unit.code !== "NONE"
                ? ts.parameter.unit.code
                : ""}
            </Item>
          </Grid>
          <Grid item lg={3} sm={6} xs={12} className="manual-data-entry-grid-item">
            <Item elevation={0}>
              <MuiPickersUtilsProvider utils={LuxonUtils}>
                <KeyboardDateTimePicker
                  fullWidth
                  label="Date/Time"
                  format="MM/dd/yyyy HH:mm"
                  value={date}
                  onChange={(date) => updateUserInputDate(date)}
                  placeholder="01/01/2021 00:00"
                  views={["date", "year", "month", "hours"]}
                  inputVariant="outlined"
                  size="small"
                  ampm={false}
                  id="date-time picker"
                />
              </MuiPickersUtilsProvider>
            </Item>
          </Grid>
          <Grid item lg={2} sm={6} xs={12} className="manual-data-entry-grid-item">
            <Item elevation={0}>
              <TextField
                fullWidth
                error={errorMessage ? true : false}
                value={formatNumber(value, 2)}
                onChange={updateValue}
                onBlur={handleOnBlur}
                placeholder="Value"
                variant="outlined"
                size="small"
                type="text"
                InputProps={{
                  // pattern: "/^-?[0-9]d*(.d+)?$/",
                  endAdornment: (
                    <InputAdornment position="end">
                      {ts.parameter.unit.code !== "NONE"
                        ? ts.parameter.unit.code
                        : ""}
                    </InputAdornment>
                  ),
                }}
                helperText={errorMessage}
                id="manual data entry value input"
              />
            </Item>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

EditableDataRow.propTypes = {
  ts: PropTypes.shape({
    id: PropTypes.string,
    code: PropTypes.string,
    entry_type: PropTypes.object,
    interval: PropTypes.number,
    latest_value: PropTypes.object,
    location: PropTypes.object,
    name: PropTypes.string,
    parameter: PropTypes.object,
    properties: PropTypes.object,
    threshold_limits: PropTypes.object,
    tree_node: PropTypes.object,
  }),
  updateDataEntryArray: PropTypes.func,
  date: PropTypes.object,
  updating: PropTypes.func,
  errorFlag: PropTypes.func,
  value: PropTypes.number,
};
