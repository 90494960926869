import React, { useState, useContext } from "react";
import axios from "axios";
import { DateTime } from "luxon";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { UserContext } from "../../providers/UserProvider";
import { userActions } from "../../reducers/userReducer.js";
import { apiRoot } from "../../services/api";
import { getRoundedDate } from "../../helpers/helpers";

const useStyles = makeStyles((theme) => ({
  table: {
    maxWidth: "100%",
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const TimeseriesTable = ({ meta, data, setData }) => {
  const classes = useStyles();
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("An error occurred.  Please contact the system administrator.");
  const { state, dispatch } = useContext(UserContext);
  const { token } = state.user;

  const handleAlertClose = (event, reason) => {
    setOpenAlert(false);
  };

  const postNewData = async (newData, resolve, reject) => {
    try {
      const res = await axios.post(
        `${apiRoot}/timeseries/${meta.id}/values/`,
        {
          datetime: getRoundedDate(1, newData.datetime),
          num_value: newData.num_value,
        },
        {
          headers: token && {
            Authorization: `Bearer ${token.access}`,
          },
        }
      );
      setData([...data, res.data]);
      resolve();
    } catch (err) {
      if (err.response.status === 401) {
        window.localStorage.removeItem("afs_token");
        window.localStorage.removeItem("afs_user");
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
        console.log(err.message);
        resolve();
        setAlertMsg("You must be authenticated to perform this action.  Please login to continue.")
        setOpenAlert(true)
      } else if (err.response.status >= 400) {
        console.log(err.message);
        resolve();
        setAlertMsg("You do not have permission to perform this action.")
        setOpenAlert(true)
      } else {
        console.log(err.message);
        resolve();
        setAlertMsg("An error occurred.  Please contact the system administrator.")
        setOpenAlert(true)
      }
    }
  };

  const updateData = async (newData, dataUpdate, index, resolve, reject) => {
    dataUpdate[index] = newData;
    try {
      await axios.patch(
        `${apiRoot}/timeseries_values/${newData.id}/`,
        {
          datetime: getRoundedDate(1, newData.datetime),
          num_value: newData.num_value,
        },
        {
          headers: token && {
            Authorization: `Bearer ${token.access}`,
          },
        }
      );
      setData([...dataUpdate]);
      resolve();
    } catch (err) {
      if (err.response.status === 401) {
        window.localStorage.removeItem("afs_token");
        window.localStorage.removeItem("afs_user");
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
        console.log(err.message);
        resolve();
        setAlertMsg("You must be authenticated to perform this action.  Please login to continue.")
        setOpenAlert(true)
      } else if (err.response.status >= 400) {
        console.log(err.message);
        resolve();
        setAlertMsg("You do not have permission to perform this action.")
        setOpenAlert(true)
      } else {
        console.log(err.message);
        resolve();
        setAlertMsg("An error occurred.  Please contact the system administrator.")
        setOpenAlert(true)
      }
    }
  };

  const deleteData = async (dataDelete, index, deleteId, resolve, reject, setOpenAlert) => {
    try {
      await axios.delete(`${apiRoot}/timeseries_values/${deleteId}/`, {
        headers: token && {
          Authorization: `Bearer ${token.access}`,
        },
      });
      dataDelete.splice(index, 1);
      setData([...dataDelete]);
      resolve();
    } catch (err) {
      if (err.response.status === 401) {
        window.localStorage.removeItem("afs_token");
        window.localStorage.removeItem("afs_user");
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
        console.log(err.message);
        resolve();
        setAlertMsg("You must be authenticated to perform this action.  Please login to continue.")
        setOpenAlert(true)
      } else if (err.response.status >= 400) {
        console.log(err.message);
        resolve();
        setAlertMsg("You do not have permission to perform this action.")
        setOpenAlert(true)
      } else {
        console.log(err.message);
        resolve();
        setAlertMsg("An error occurred.  Please contact the system administrator.")
        setOpenAlert(true)
      }
    }
  };

  return (
    <div
      className={classes.table}
      role="table"
      aria-label="table of environmental data"
    >
      <MaterialTable
        columns={[
          {
            title: "Datetime (" + DateTime.now().toFormat("ZZZZ") + ")",
            field: "datetime",
            type: "datetime",
          },
          {
            title: "Value", 
            field: "num_value" 
          },
        ]}
        data={data}
        title={null}
        options={{
          exportButton: true,
          exportAllData: true,
          exportFileName: meta.name,
        }}
        editable={{
          onRowAddCancelled: (rowData) => console.log("Row adding cancelled"),
          onRowUpdateCancelled: (rowData) =>
            console.log("Row editing cancelled"),
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                postNewData(newData, resolve, reject, setOpenAlert, setAlertMsg);
              }, 1000);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                const dataUpdate = [...data];
                const index = oldData.tableData.id;
                updateData(newData, dataUpdate, index, resolve, reject, setOpenAlert, setAlertMsg);
              }, 1000);
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                const dataDelete = [...data];
                const index = oldData.tableData.id;
                const deleteId = dataDelete[index].id;
                deleteData(dataDelete, index, deleteId, resolve, reject, setOpenAlert, setAlertMsg);
              }, 1000);
            }),
        }}
      />
      {openAlert &&
        <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleAlertClose}>
          <Alert onClose={handleAlertClose} severity="error">
            {alertMsg}
          </Alert>
        </Snackbar>
      }
    </div>
  );
};
