import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core";
import LinkIcon from "@material-ui/icons/Link";
import { linksList } from "./UsefulLinksInfo";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  secondaryBar: {
    display: "flex",
    marginBottom: theme.spacing(1),
  },
  paper: {
    display: "flex-grow",
  },
}));

export const UsefulLinks = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AppBar
        className={classes.secondaryBar}
        position="static"
        color="default"
      >
        <Toolbar>
          <Typography variant="h6">Useful Links</Typography>
        </Toolbar>
      </AppBar>

      <Paper className={classes.paper}>
        <List>
          {linksList.map((link, index) => {
            return (
              <ListItem
                button
                component="a"
                target="_blank"
                href={link.url}
                key={index}
              >
                <ListItemIcon>
                  <LinkIcon />
                </ListItemIcon>
                <ListItemText primary={link.text} secondary={link.url} style={{ color: "blue" }} />
              </ListItem>
            );
          })}
        </List>
      </Paper>
    </div>
  );
};
