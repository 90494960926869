import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Paper,
  Typography,
  Divider,
} from "@material-ui/core";
import logo from "../../images/nhdesLogo.png";
import { HelpDocs } from "./HelpDocs"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    display: "flex-grow",
    padding: theme.spacing(2),
    textAlign: 'center',
    alignItems: "center",
  },
  header: {
    color: "red",
    textAlign: 'center',
  },
  body: {
    paddingTop: theme.spacing(2),
    textAlign: 'left',
  }
}));

export const Home = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container direction="row" alignItems="stretch" spacing={1}>
        <Grid item xs={12} md={6}>
          <Paper className={classes.paper}>
            <Typography variant="h3">
              New Hampshire Department of Environmental Services
            </Typography>
            <Typography variant="h5">
              Real-Time Data & Information for Watersheds
            </Typography>
          </Paper>
          <Paper className={classes.paper}>
            <img
              src={logo}
              alt="New Hampshire Department of Environmental Services Logo"
              width={"70%"}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper className={classes.paper}>
          <Typography variant="h4">
              Getting Started
            </Typography>
            <Divider />
            <Typography className={classes.body}>
              To begin viewing Observed Data on the Real-Time Data page please select the “Observations” tab in the left side-bar.
            </Typography>
            <HelpDocs />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};
