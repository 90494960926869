import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import {
  makeStyles,
  CircularProgress,
  List,
  Typography,
} from "@material-ui/core";
import { apiRoot } from "../../services/api";
import { userActions } from "../../reducers/userReducer";
import { UserContext } from "../../providers/UserProvider";
import { StationDetailList } from "./StationDetailList";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export const StationInformation = ({ feature }) => {
  const classes = useStyles();

  const { state, dispatch } = useContext(UserContext);
  const { token } = state.user;

  const [hasError, setErrors] = useState(false);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);

  useEffect(() => {
    const fetchStationOperations = async () => {
      setLoading(true);
      try {
        const res = await axios.get(apiRoot + "/location_details/", {
          params: {
            location: feature.id,
            type_code: "DISCLAIMER,INFO",
          },
          headers: token && {
            Authorization: `Bearer ${token.access}`,
          },
        });
        setList(res.data);
        setLoading(false);
      } catch (err) {
        if (err.response.status === 401) {
          window.localStorage.removeItem("afs_token");
          window.localStorage.removeItem("afs_user");
          dispatch(userActions.setToken(null));
          dispatch(userActions.setMe(null));
          setErrors("Unauthorized");
          setLoading(false);
        } else if (err.response.status === 403) {
          setErrors(
            "You do not have the appropriate permissions to view / use this resource."
          );
          setLoading(false);
        } else {
          console.log(err);
          setErrors(err.message);
          setLoading(false);
        }
      }
    };

    fetchStationOperations();
  }, [feature, dispatch, token]);

  return (
    <div className={classes.root}>
      <>
        {!loading ? (
          !hasError ? (
            list.length > 0 ? (
              <List component="nav" aria-label="main info folder">
                <StationDetailList list={list} />
              </List>
            ) : (
              <Typography>
                There is no station information to display for this station.
              </Typography>
            )
          ) : (
            <Typography>Error: {JSON.stringify(hasError)}</Typography>
          )
        ) : (
          <CircularProgress />
        )}
      </>
    </div>
  );
};
