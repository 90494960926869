import { useState, useEffect, useContext } from "react";
import { apiRoot } from "../services/api";
import { userActions } from "../reducers/userReducer.js";
import { UserContext } from "../providers/UserProvider";
import { DateTime } from "luxon";

const axios = require("axios");

export const useGetPlotConfig = (selectedPlotConfig, feature, startDatetime, endDatetime) => {
  const { state, dispatch } = useContext(UserContext);
  const [plotConfig, setPlotConfig] = useState([]);
  const [loadingPlotConfig, setLoadingSelectedConfig] = useState(true);
  const [getPlotConfigError, setGetPlotConfigError] = useState(false);
  const { token } = state.user;

  useEffect(() => {
    setGetPlotConfigError(null);

    const fetchPlot = async () => {
      if (selectedPlotConfig) {
        setLoadingSelectedConfig(true);
        try {
          const res = await axios.post(
            apiRoot + "/plotly/",
            {
              plot_id: selectedPlotConfig.id,
              feature_id: feature.id,
              tzname: DateTime.now().zoneName,
              start_dt: startDatetime,
              end_dt: endDatetime
            },
            {
              headers: token && {
                Authorization: `Bearer ${token.access}`,
              },
            }
          );
          setPlotConfig(res.data);
          setLoadingSelectedConfig(false);
        } catch (err) {
          if (err.response.status === 401) {
            window.localStorage.removeItem("afs_token");
            window.localStorage.removeItem("afs_user");
            dispatch(userActions.setToken(null));
            dispatch(userActions.setMe(null));
            setGetPlotConfigError("Unauthorized");
            setLoadingSelectedConfig(false);
          } else if (err.response.status === 403) {
            setGetPlotConfigError(
              "You do not have the appropriate permissions to view / use this resource."
            );
            setLoadingSelectedConfig(false);
          } else {
            console.log(err);
            setGetPlotConfigError(err.message);
            setLoadingSelectedConfig(false);
          }
        }
      }
    };

    setPlotConfig(null);
    fetchPlot();
  }, 
  [selectedPlotConfig, feature, dispatch, token, startDatetime, endDatetime]
  );

  return { plotConfig, loadingPlotConfig, getPlotConfigError };
};
