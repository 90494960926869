import React, { useState, useEffect } from "react";
import { makeStyles, Paper, CircularProgress } from "@material-ui/core";
import Plot from "react-plotly.js";

const useStyles = makeStyles((theme) => ({
  plot: {
    width: "100%",
    height: "100%",
  },
  paper: {
    width: "100%",
    height: "100%",
  },
}));

var disk = {
  // Plotly disk: https://github.com/plotly/plotly.js/blob/master/src/fonts/ploticon.js
  'width': 857.1,
  'height': 1000,
  'path': 'm214-7h429v214h-429v-214z m500 0h72v500q0 8-6 21t-11 20l-157 156q-5 6-19 12t-22 5v-232q0-22-15-38t-38-16h-322q-22 0-37 16t-16 38v232h-72v-714h72v232q0 22 16 38t37 16h465q22 0 38-16t15-38v-232z m-214 518v178q0 8-5 13t-13 5h-107q-7 0-13-5t-5-13v-178q0-8 5-13t13-5h107q7 0 13 5t5 13z m357-18v-518q0-22-15-38t-38-16h-750q-23 0-38 16t-16 38v750q0 22 16 38t38 16h517q23 0 50-12t42-26l156-157q16-15 27-42t11-49z',
  'transform': 'matrix(1 0 0 -1 0 850)'
}

// let disk = {
//   'name': "download",
//   'svg': '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M384 128h-128V0L384 128zM256 160H384v304c0 26.51-21.49 48-48 48h-288C21.49 512 0 490.5 0 464v-416C0 21.49 21.49 0 48 0H224l.0039 128C224 145.7 238.3 160 256 160zM255 295L216 334.1V232c0-13.25-10.75-24-24-24S168 218.8 168 232v102.1L128.1 295C124.3 290.3 118.2 288 112 288S99.72 290.3 95.03 295c-9.375 9.375-9.375 24.56 0 33.94l80 80c9.375 9.375 24.56 9.375 33.94 0l80-80c9.375-9.375 9.375-24.56 0-33.94S264.4 285.7 255 295z"/></svg>'
// }

export const ConfigurationPlot = ({ config, plotConfig }) => {
  const classes = useStyles();
  const [data, setData] = useState(null);
  const [layout, setLayout] = useState(null);

  useEffect(() => {
    if (config) {
      setData(config.data);
      setLayout(config.layout);
    }
  }, [config]);

  const getFileName = (layout) => {
    let title = layout.title.text;
    let cleanedTitle = (plotConfig.name + "_" + title)
      .replace(/<\/?[^>]+(>|$)/g, "_")
      .replace(/[^a-zA-Z0-9]/g, "_")
      .replace(" ", "_")
      .replace(/_+/g, "_");
    if (cleanedTitle.endsWith("_")) {
      cleanedTitle = cleanedTitle.substring(0, cleanedTitle.length - 1);
    }
    return cleanedTitle;
  };

  const csvDownload = {
    name: 'downloadCsv',
    title: 'Download data as csv',
    icon: disk,
    click: function () {
      // Borrowed some from here https://github.com/plotly/plotly.js/issues/2171
      var csvData = [];
      // Create header
      var header = ['Datetime']
      let dtArr = [];
      data.forEach((trace) => {
        header.push(trace.name);
        dtArr = dtArr.concat(trace.x);
      });
      csvData.push(header);

      // Remove dupplicate dtaetime data
      let dtArrNoDups = [...new Set([...dtArr])];

      // Create rows
      dtArrNoDups.forEach((dt) => {
        let rowArr = [];
        rowArr.push(dt)
        data.forEach((trace) => {
          rowArr.push(trace.y[trace.x.indexOf(dt)]);
        });
        csvData.push(rowArr);
      });
      var csvFile = csvData.map(e => e.map(a => '"' + ((a ?? "").toString().replace(/"/gi, '""')) + '"').join(",")).join("\r\n");
      var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
      var link = document.createElement("a");
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", getFileName(layout) + ".csv");
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <Paper className={classes.paper}>
      {data && layout ? (
        <div
          role="img"
          aria-label="plot of environmental data"
          style={{ height: "100%" }}
        >
          <Plot
            data={data}
            layout={layout}
            useResizeHandler={true}
            config={{
              displaylogo: false,
              toImageButtonOptions: {
                filename: getFileName(layout),
              },
              modeBarButtons: [
                [csvDownload],
                [
                  'toImage',
                  'zoom2d',
                  'pan2d',
                  'autoScale2d',
                  'resetScale2d',
                  'toggleSpikelines',
                  'toggleHover',
                  'hoverCompareCartesian',
                ]
              ]
              // modeBarButtonsToAdd: [{ name: 'downloadCsv', title: 'Download data as csv', icon: disk, gravity: 'ne', click: function(){
              //   // Borrowed some from here https://github.com/plotly/plotly.js/issues/2171
              //   var csvData = [];
              //   // Create header
              //   var header = ['datetime']
              //   let dtArr = [];
              //   data.forEach((trace) => {
              //     header.push(trace.name);
              //     dtArr = dtArr.concat(trace.x);
              //   });
              //   csvData.push(header);

              //   // Remove dupplicate dtaetime data
              //   let dtArrNoDups = [...new Set([...dtArr])];

              //   // Create rows
              //   dtArrNoDups.forEach((dt) => {
              //     let rowArr = [];
              //     rowArr.push(dt)
              //     data.forEach((trace) => {
              //       rowArr.push(trace.y[trace.x.indexOf(dt)]);
              //     });
              //     csvData.push(rowArr);
              //   });
              //   var csvFile = csvData.map(e=>e.map(a=>'"'+((a??"").toString().replace(/"/gi,'""'))+'"').join(",")).join("\r\n"); 
              //   var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
              //   var link = document.createElement("a");
              //   var url = URL.createObjectURL(blob);
              //   link.setAttribute("href", url);
              //   link.setAttribute("download", layout.title.text.replace(/[^a-z0-9_.-]/gi,'_') + ".csv");
              //   link.style.visibility = 'hidden';
              //   document.body.appendChild(link);
              //   link.click();
              //   document.body.removeChild(link);
              //   } }],
            }}
            className={classes.plot}
          />
        </div>
      ) : (
        <CircularProgress />
      )}
    </Paper>
  );
};
