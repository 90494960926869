import { useState, useEffect, useContext } from "react";
import { apiRoot } from "../services/api";
import { userActions } from "../reducers/userReducer.js";
import { UserContext } from "../providers/UserProvider";
import { poolStationMapping } from "./poolStationMapping";

const axios = require("axios");

export const useGetStationPoolTs = (feature) => {
  const { state, dispatch } = useContext(UserContext);
  const [poolTs, setPoolTs] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { token } = state.user;

  const checkForOverrideCode = (code) => {
    if (poolStationMapping[code]) {
      return poolStationMapping[code]
    }
    return code
  }

  useEffect(() => {
    const fetchStationPoolTs = async () => {
      setLoading(true);
      try {
        const res = await axios.get(apiRoot + "/timeseries/", {
          params: {
            location__code: checkForOverrideCode(feature.properties.code),
            parameter__code: "PELV"
          },
          headers: token && {
            Authorization: `Bearer ${token.access}`,
          },
        });

        let { data } = res;
        if (data.length > 0) {
          setPoolTs(data[0]);
        }
        setLoading(false);
      } catch (err) {
        if (err.response.status === 401) {
          window.localStorage.removeItem("afs_token");
          window.localStorage.removeItem("afs_user");
          dispatch(userActions.setToken(null));
          dispatch(userActions.setMe(null));
          setError("Unauthorized");
          setLoading(false);
        } else if (err.response.status === 403) {
          setError(
            "You do not have the appropriate permissions to view / use this resource."
          );
          setLoading(false);
        } else {
          console.log(err);
          setError(err.message);
          setLoading(false);
        }
      }
    };

    fetchStationPoolTs();
  }, 
  [feature, dispatch, token]
  );

  return { poolTs, loading, error };
};
