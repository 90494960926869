import React, { useState, useEffect, useContext } from "react";
import {
  makeStyles,
  List,
  ListItem,
  Typography,
  Paper,
  AppBar,
  Toolbar,
  Button,
  TextField,
  Card,
  CardActions,
  CardContent,
} from "@material-ui/core";
import axios from "axios";
import { DateTime } from "luxon";
import { apiRoot } from "../../services/api";
import { UserContext } from "../../providers/UserProvider";
import { userActions } from "../../reducers/userReducer.js";

const parseDateTime = (nodeName) => {
  let dt = DateTime.fromISO(nodeName);
  return dt.toFormat("y-LL-dd HH:mm ZZZZ");
};

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
  },
  card: {
    width: "100%",
  },
  title: {
    fontSize: 14,
    color: theme.palette.primary,
  },
}));

const DetailForm = ({ detail }) => {
  const { state, dispatch } = useContext(UserContext);
  const { token } = state.user;

  const [hasError, setErrors] = useState(false);

  const classes = useStyles();
  const [message, setMessage] = useState("");
  const [updated, setUpdated] = useState(null);
  const [dirty, setDirty] = useState(false);

  useEffect(() => {
    setMessage(detail.text);
    setUpdated(detail.modified_on);
  }, [detail]);

  const handleChange = (event) => {
    setMessage(event.target.value);
    setDirty(true);
  };

  const handleCancel = (event) => {
    setMessage(detail.text);
    setDirty(false);
  };

  const handleSave = async () => {
    try {
      const res = await axios.patch(
        apiRoot + "/location_details/" + detail.id + "/",
        {
          text: message,
        },
        {
          headers: token && {
            Authorization: `Bearer ${token.access}`,
          },
        }
      );

      setDirty(false);
      setUpdated(res.data.modified_on);
    } catch (err) {
      if (err.response.status === 401) {
        window.localStorage.removeItem("afs_token");
        window.localStorage.removeItem("afs_user");
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
        setErrors("Unauthorized");
      } else if (err.response.status === 403) {
        setErrors(
          "You do not have the appropriate permissions to view / use this resource."
        );
      } else {
        console.log(err);
        setErrors(err.message);
      }
    }
  };

  return (
    <ListItem className={classes.root}>
      <Card className={classes.card} variant="outlined">
        <CardContent>
          <Typography className={classes.title} gutterBottom>
            {detail.name}
          </Typography>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            Last updated at: {parseDateTime(updated)}
          </Typography>
          <TextField
            id="outlined-multiline-static"
            label="Message"
            fullWidth
            margin="normal"
            multiline
            // rows={4}
            value={message}
            variant="outlined"
            onChange={handleChange}
          />
        </CardContent>
        <CardActions>
          <Button size="small" color="primary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            size="small"
            color="primary"
            disabled={!dirty}
            onClick={handleSave}
          >
            Save
          </Button>
        </CardActions>
      </Card>
      {hasError && <Typography>Error: {JSON.stringify(hasError)}</Typography>}
    </ListItem>
  );
};

export const OperationsUpdates = () => {
  const { state, dispatch } = useContext(UserContext);
  const { token } = state.user;

  const [hasError, setErrors] = useState(false);
  const [details, setDetails] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(apiRoot + "/location_details/", {
          params: {
            enabled: "True",
            type_code: "UPDATE",
          },
          headers: token && {
            Authorization: `Bearer ${token.access}`,
          },
        });
        setDetails(res.data);
      } catch (err) {
        if (err.response.status === 401) {
          window.localStorage.removeItem("afs_token");
          window.localStorage.removeItem("afs_user");
          dispatch(userActions.setToken(null));
          dispatch(userActions.setMe(null));
          setErrors("Unauthorized");
        } else if (err.response.status === 403) {
          setErrors(
            "You do not have the appropriate permissions to view / use this resource."
          );
        } else {
          console.log(err);
          setErrors(err.message);
        }
      }
    };

    fetchData();
  }, [dispatch, token]);

  return (
    <Paper>
      <AppBar position="static" color="default">
        <Toolbar>
          <Typography variant="h6">Operational Updates</Typography>
        </Toolbar>
      </AppBar>
      <List>
        {!hasError ? (
          details.map((s) => <DetailForm key={s.id} detail={s} />)
        ) : (
          <Typography>Error: {JSON.stringify(hasError)}</Typography>
        )}
      </List>
    </Paper>
  );
};
