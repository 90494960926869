import React, {useState} from "react";
import PropTypes from 'prop-types';
import {
  Button,
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import {DateTimePicker, MuiPickersUtilsProvider,} from "@material-ui/pickers";
import LuxonUtils from '@date-io/luxon';

export default function DatetimeSelector(props) {
  const {startDatetime, setStartDatetime, endDatetime, setEndDatetime, open, handleClose} = props;
  const [selectedStart, setSelectedStart] = useState(startDatetime);
  const [selectedEnd, setSelectedEnd] = useState(endDatetime);

  const handleChange = () => {
    setStartDatetime(selectedStart);
    setEndDatetime(selectedEnd);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="change_datetime-dialog-title">
      <DialogTitle id="form-dialog-title">Change Selected Start and End Times</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Select start and end dates to change the time period shown.
          Observed timeseries default to the last 30 days.
          Forecast timeseries default to showing the entire period
        </DialogContentText>
        <MuiPickersUtilsProvider utils={LuxonUtils}>
          <DateTimePicker
            label="Start Datetime"
            value={selectedStart}
            onChange={setSelectedStart}
            showTodayButton
          />
          <DateTimePicker
            label="End Datetime"
            value={selectedEnd}
            onChange={setSelectedEnd}
            showTodayButton
          />
        </MuiPickersUtilsProvider>

      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleChange} color="primary">
          Change
        </Button>
      </DialogActions>
    </Dialog>
  )

}

DatetimeSelector.propTypes = {
  startDatetime: PropTypes.any.isRequired,
  setStartDatetime: PropTypes.any.isRequired,
  endDatetime: PropTypes.any.isRequired,
  setEndDatetime: PropTypes.any.isRequired,
  open: PropTypes.any.isRequired,
  handleClose: PropTypes.any.isRequired,
};