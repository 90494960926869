import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid, Box, Paper, TextField, InputAdornment } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import {
  formatNumber,
} from "../../helpers/helpers";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  margin: 0,
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

export const DischargeCalculationRow = ({
  ts,
  updateGatePositions,
  dischargeEstimate,
  gateValue,
  disabled,
}) => {
  const [userInputValue, setUserInputValue] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const validate = () => {
    setErrorMessage("");
    let isValid = true;

    userInputValue !== "" &&
      userInputValue !== null &&
      (isValid =
        (ts.threshold_limits.min <= parseFloat(userInputValue) && validNumber(userInputValue) &&
          parseFloat(userInputValue) <= ts.threshold_limits.max) ||
          ts.threshold_limits.min == null ||
          ts.threshold_limits.max == null
          ? true
          : false);
    !isValid && setErrorMessage("Choose value within range");

    return isValid;
  };

  const validNumber = (str) => {
    // This check that the number is a valid number for this system
    const regex = /^-?([0]{1}\.{1}[0-9]+|[1-9]{1}[0-9]*\.{1}[0-9]+|[0-9]+|0)?$/g;
    if (str.match(regex) && str.match(regex).length === 1) {
      return true;
    }
    return false;
  }

  const updateValue = (e) => {
    setErrorMessage("");
    const value = e.target.value;
    const regex = /^-?[0-9.]*$/g;
    if (value.match(regex) && value.match(regex).length === 1) {
      setUserInputValue(value);
    }
  };

  useEffect(() => {
    validate()
    if (userInputValue !== null) {
      updateGatePositions({
        timeseries: ts,
        num_value: userInputValue,
        discharge_estimate: null
      });
    }
    // eslint-disable-next-line
  }, [userInputValue]);

  return (
    <>
      <Box className="manual-data-entry-row">
        <Grid container columns={12}>
          <Grid item sm={3} className="manual-data-entry-grid-item">
            <Item elevation={0}>{ts.name}</Item>
          </Grid>
          <Grid item sm={3} className="manual-data-entry-grid-item">
            <Item elevation={0}>
              Range: {ts.threshold_limits.min}{" "} - {ts.threshold_limits.max}{" "}
              {ts.parameter.unit.code !== "NONE" ? ts.parameter.unit.code : ""}
            </Item>
          </Grid>
          <Grid item sm={3} className="manual-data-entry-grid-item">
            <Item elevation={0}>
              <TextField
                disabled={disabled}
                error={errorMessage ? true : false}
                value={formatNumber(gateValue, 2)}
                onChange={updateValue}
                onBlur={validate}
                placeholder="Value"
                variant="outlined"
                size="small"
                type="text"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {ts.parameter.unit.code !== "NONE"
                        ? ts.parameter.unit.code
                        : ""}
                    </InputAdornment>
                  ),
                }}
                // helperText={errorMessage}
                id="manual data entry value input"
              />
            </Item>
          </Grid>
          <Grid item sm={3} className="manual-data-entry-grid-item">
            <Item elevation={0}>
              <TextField
                disabled
                value={formatNumber(dischargeEstimate)}
                variant="outlined"
                size="small"
                type="text"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      CFS
                    </InputAdornment>
                  ),
                }}
                id="manual data entry value input"
              />
            </Item>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

DischargeCalculationRow.propTypes = {
  ts: PropTypes.shape({
    id: PropTypes.string,
    code: PropTypes.string,
    entry_type: PropTypes.object,
    interval: PropTypes.number,
    latest_value: PropTypes.object,
    location: PropTypes.object,
    name: PropTypes.string,
    parameter: PropTypes.object,
    properties: PropTypes.object,
    threshold_limits: PropTypes.object,
    tree_node: PropTypes.object,
  }),
  updateGatePositions: PropTypes.func,
  clearDataFlag: PropTypes.bool,
  dischargeEstimate: PropTypes.number,
  gateValue: PropTypes.string,
  disabled: PropTypes.bool,
};
