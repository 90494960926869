import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import Typography from "@material-ui/core/Typography";
import { useGetStationList } from "../../hooks/useGetStationList";
import { apiRoot } from "../../services/api";
import { UserContext } from "../../providers/UserProvider";
import { userActions } from "../../reducers/userReducer.js";
import { StationGeoJSON } from "../Mapping/StationGeoJSON";
import { SubbasinGeoJSON } from "../Mapping/SubbasinGeoJSON";
import "../../css/Map.css";

const ZoomMapToSelected = (props) => {
  const { subbasinsRef, selSubbasinFeats } = props;
  const map = useMap();
  useEffect(() => {
    const zoomMap = () => {
      if (subbasinsRef && selSubbasinFeats.length > 0) {
        if (subbasinsRef.current) {
          map.fitBounds(subbasinsRef.current.getBounds());
        }
      }
    };
    zoomMap();
  }, [selSubbasinFeats, subbasinsRef, map]);
  return null;
};

export const ForecastMap = ({ setSelectedFeature, selectedBasinNode }) => {
  const { state, dispatch } = useContext(UserContext);
  const { token } = state.user;
  const { stationList, loadingList, error } = useGetStationList();
  const [hasError, setErrors] = useState(false);
  const [subbasins, setSubbasins] = useState(null);
  const [forecastPoints, setForecastPoints] = useState(null);
  const [selSubbasinFeats, setSelSubbasinFeats] = useState([]);
  const [selForecastFeats, setSelForecastFeats] = useState([]);

  const lat = 44;
  const lng = -71.5;
  const zoom = 8;
  const position = [lat, lng];

  useEffect(() => {
    const fetchSubBasins = async () => {
      try {
        const res = await axios.get(apiRoot + "/locations/", {
          params: {
            layer__code: "SUBBASINS",
          },
          headers: token && {
            Authorization: `Bearer ${token.access}`,
          },
        });
        setSubbasins(res.data);
      } catch (err) {
        if (err.response.status === 401) {
          window.localStorage.removeItem("afs_token");
          window.localStorage.removeItem("afs_user");
          dispatch(userActions.setToken(null));
          dispatch(userActions.setMe(null));
          setErrors("Unauthorized");
        } else if (err.response.status === 403) {
          setErrors(
            "You do not have the appropriate permissions to view / use this resource."
          );
        } else {
          console.log(err);
          setErrors(err.message);
        }
      }
    };
    !loadingList && error && setErrors(error);
    !loadingList && stationList && setForecastPoints(stationList);
    fetchSubBasins();
  }, [dispatch, token, loadingList, error, stationList]);

  useEffect(() => {
    const reorderLayer = () => {
      if (forecastPointsRef.current) {
        forecastPointsRef.current.bringToFront();
      }
    };
    reorderLayer();
  }, [selSubbasinFeats]);

  useEffect(() => {
    const filterFeats = () => {
      if (forecastPoints && subbasins) {
        const filtPointFeats = forecastPoints.filter(
          (feat) =>
            feat.properties.parent === selectedBasinNode.properties.basinId
        );
        // console.log("selecting points " + filtPointFeats.length, filtPointFeats)

        const subbasinFeats = subbasins.features;
        const filtSubbasinFeats = subbasinFeats.filter(
          (feat) =>
            feat.properties.parent === selectedBasinNode.properties.basinId
        );
        // console.log("selecting polygons " + filtSubbasinFeats.length, filtSubbasinFeats)

        setSelForecastFeats(filtPointFeats);
        if (forecastPointsRef.current) {
          forecastPointsRef.current.clearLayers().addData(filtPointFeats);
        }

        setSelSubbasinFeats(filtSubbasinFeats);
        if (subbasinsRef.current) {
          subbasinsRef.current.clearLayers().addData(filtSubbasinFeats);
        }
      }
    };
    filterFeats();
  }, [forecastPoints, subbasins, selectedBasinNode]);

  const subbasinsRef = useRef();
  const forecastPointsRef = useRef();

  return (
    <>
      {!hasError ? (
          <MapContainer center={position} zoom={zoom}>
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <StationGeoJSON
              geoJsonRef={forecastPointsRef}
              features={selForecastFeats}
              setSelectedFeature={setSelectedFeature}
            />
            <SubbasinGeoJSON
              geoJsonRef={subbasinsRef}
              features={selSubbasinFeats}
              setSelectedFeature={setSelectedFeature}
              selectable={false}
            />
            <ZoomMapToSelected
              subbasinsRef={subbasinsRef}
              selSubbasinFeats={selSubbasinFeats}
            />
          </MapContainer>
      ) : (
        <Typography>Error: {JSON.stringify(hasError)}</Typography>
      )}
    </>
  );
};
