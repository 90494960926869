import { useState, useContext } from "react";
import { pairedRoot } from "../services/api";
import { userActions } from "../reducers/userReducer.js";
import { UserContext } from "../providers/UserProvider";

const axios = require("axios");

export const useCalculateDischarge = () => {
  const { state, dispatch } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dischargeEstimates, setDischargeEstimates] = useState([]);
  const { token } = state.user;

  const transformPayload = (userDataArray, poolElev) => {
    return userDataArray.map((obj) => {
      return {
          "location_code": obj.timeseries.location.code,
          "ref_parameter_code": obj.timeseries.parameter.code,
          "x_value": poolElev,
          "ref_value": obj.num_value,
          "ref_variant": obj.timeseries.variant
      }
    })
  }

  const fetchDischargeEstimates = async (userDataArray, poolElev) => {
    setError(null)
    setLoading(true);
    try {
      const res = await axios.post(pairedRoot + "/paired_lookup/", 
      transformPayload(userDataArray, poolElev),
      {
        headers: token && {
          Authorization: `Bearer ${token.access}`,
        },
      });

      let { data } = res;
      setDischargeEstimates(data)
      setLoading(false);
    } catch (err) {
      if (err.response.status === 401) {
        window.localStorage.removeItem("afs_token");
        window.localStorage.removeItem("afs_user");
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
        setError("Unauthorized");
        setLoading(false);
      } else if (err.response.status === 403) {
        setError(
          "You do not have the appropriate permissions to view / use this resource."
        );
        setLoading(false);
      } else {
        console.log(err);
        setError(err.response.data.detail);
        setLoading(false);
      }
    }
  };

  return { fetchDischargeEstimates, dischargeEstimates, setDischargeEstimates, loading, error };
};
