import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
  Toolbar,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  useMediaQuery,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { StationPlot } from "../Plotting/StationPlot";
import { StationOperations } from "./StationOperations";
import { StationInformation } from "./StationInformation";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  tabPanel: {
    padding: theme.spacing(1),
    overflow: "auto",
  },
  locationHeader: {
    flexGrow: 1,
  },
  backButton: {
    marginRight: "auto"
  },
  toolbar: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className={classes.tabPanel}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function getDatumText(feature) {
  let fields = feature.properties.fields;
  let datumTextObj = fields.find(
    (element) => element.attribute_name.code === "DATUM_TEXT"
  );
  return datumTextObj ? datumTextObj.char_value : null;
}

export const StationDetail = ({ feature, setShowMap }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSetShowMap = () => {
    setShowMap(true);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default" className={classes.toolbar}>
        <Toolbar className={classes.toolbar}>
          {isSmallScreen &&
            (
              <IconButton
                aria-label="back to map"
                aria-controls="map-appbar"
                onClick={handleSetShowMap}
                color="primary"
                className={classes.backButton}
              >
                <ChevronLeftIcon />
              </IconButton>
            )}

          <Typography className={classes.locationHeader} variant={"h6"}>
            {feature.properties.name} ({feature.properties.code})
          </Typography>
        </Toolbar>
      </AppBar>

      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="black"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Timeseries Data" {...a11yProps(0)} />
          <Tab label="Operations Updates" {...a11yProps(1)} />
          <Tab label="Station Information" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} dir={theme.direction}>
        <StationPlot feature={feature} />
        {getDatumText(feature) && (
          <List>
            <ListItem disablePadding>
              <ListItemIcon>
                <InfoIcon />
              </ListItemIcon>
              <ListItemText primary={getDatumText(feature)} />
            </ListItem>
          </List>
        )}
      </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction}>
        <StationOperations feature={feature} />
      </TabPanel>
      <TabPanel value={value} index={2} dir={theme.direction}>
        <StationInformation feature={feature} />
      </TabPanel>
    </div>
  );
};
