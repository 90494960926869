import React, { useState, useContext, useEffect } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Route, NavLink, Switch, useLocation } from "react-router-dom";
import {
  Hidden,
  ListItem,
  ListItemIcon,
  ListItemText,
  List,
  Typography,
  Divider,
  IconButton,
  AppBar,
  Toolbar,
  CssBaseline,
  Drawer,
  useMediaQuery,
  Menu,
  MenuItem,
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import MenuIcon from "@material-ui/icons/Menu";
import TimelineIcon from "@material-ui/icons/Timeline";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MapIcon from "@material-ui/icons/Map";
import EditIcon from "@material-ui/icons/Edit"
import TocIcon from "@material-ui/icons/Toc";
import ViewComfyIcon from "@material-ui/icons/ViewComfy";
import LinkIcon from "@material-ui/icons/Link";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import HomeIcon from "@material-ui/icons/Home";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import AccountTreeIcon from "@material-ui/icons/AccountTree";

import { useUsers } from "./hooks/userAPI";
import { adminUrl } from "./services/api";
import { UserContext } from "./providers/UserProvider";
import { Home } from "./components/Home/Home.jsx"
import { Login } from "./components/Login/Login.jsx"
import { Forecasts } from "./components/Forecasts/Forecasts.jsx";
import { Stations } from "./components/Stations/Stations.jsx";
import { Browser } from "./components/Timeseries/Browser.jsx";
import { OperationsUpdates } from "./components/OperationsUpdates/OperationsUpdates";
import { ManualDataEntry } from "./components/ManualDataEntry/ManualDataEntry";
import { SnowData } from "./components/SnowData/SnowData";
import { Alerts } from "./components/Alerts/Alerts";
import { PrecipTilesContainer } from "./components/GriddedData/PrecipTilesContainer";
import { UsefulLinks } from "./components/UsefulLinks/UsefulLinks";
import ProtectedRoute from "./components/Utils/ProtectedRoute.jsx";
import logo from "./images/nhdesLogo.png";
import amanzi from "./images/RTI_Amanzi_logo_endorse_rgb_sm.png";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      flexShrink: 0,
    },
    background: "#E6E6E6",
    color: "black",
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    background: "#E6E6E6",
    color: "black",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  title: {
    flexGrow: 1,
    color: "black",
    marginLeft: "2%",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up("sm")]: {
      marginLeft: -drawerWidth,
    },
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  user: {
    padding: theme.spacing(1),
  },
  amanziText: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    fontSize: 12,
  },
}));

export default function ResponsiveDrawer(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [isMobileOpen, setIsMobileOpen] = React.useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { logout, isAdmin, isAuthenticated, isForecaster } = useUsers();
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const { state } = useContext(UserContext);
  const { me } = state.user;

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSmallScreenDrawerToggle = () => {
    setIsMobileOpen(!isMobileOpen);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleLogout = () => {
    logout(props);
    handleClose();
  };

  const mainContent = (
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/" component={Home} />
      <Route path="/stations" component={Stations} />
      <Route path="/useful_links" component={UsefulLinks} />
      <Route path="/snow_data" component={SnowData} />
      <ProtectedRoute path="/forecasts" component={Forecasts} />
      <ProtectedRoute path="/operations" component={OperationsUpdates} />
      <ProtectedRoute path="/browser" component={Browser} />
      <ProtectedRoute path="/alerts" component={Alerts} />
      <ProtectedRoute path="/gridded_data" component={PrecipTilesContainer} />
      <ProtectedRoute path="/manual_data_entry" component={ManualDataEntry} />
    </Switch>
  );

  const location = useLocation();

  const CustomListItem = ({ to, primary, icon }) => {
    return (
      <ListItem
        button
        component={NavLink}
        to={to}
        selected={to === location.pathname}
        onClick={isSmallScreen ? handleSmallScreenDrawerToggle : handleDrawerOpen}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={primary} />
      </ListItem>
    );
  };

  const drawerContent = (
    <div>
      {/* <div className={classes.drawerHeader}>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon titleAccess="Close Sidebar Navigation" />
        </IconButton>
      </div> */}
      {/* <Divider /> */}
      <List>
        <CustomListItem to="/" primary="Home" icon={<HomeIcon />} />
        <CustomListItem to="/stations" primary="Observations" icon={<TimelineIcon />} />
        {(isForecaster() || isAdmin()) &&
          <div>
            <CustomListItem
              to="/forecasts"
              primary="Forecasts"
              titleAccess="Forecasts Icon Link"
              icon={<MapIcon />}
            />
            <CustomListItem
              to="/browser"
              primary="Timeseries"
              icon={<TimelineIcon />}
            />
            <CustomListItem
              to="/operations"
              primary="Operations Updates"
              icon={<TocIcon />}
            />
            <CustomListItem
              to="/manual_data_entry"
              primary="Manual Data Entry"
              icon={<EditIcon />}
            />
            <CustomListItem
              to="/alerts"
              primary="Alerts"
              icon={<NotificationImportantIcon />}
            />
            <CustomListItem
              to="/gridded_data"
              primary="Gridded Data"
              icon={<ViewComfyIcon />}
            />
          </div>
        }
        <ListItem button component={NavLink} to="/snow_data">
          <ListItemIcon>
            <LinkIcon titleAccess="Snow data PDFs" />
          </ListItemIcon>
          <ListItemText primary="Snow Data" />
        </ListItem>

        <ListItem button component={NavLink} to="/useful_links">
          <ListItemIcon>
            <LinkIcon titleAccess="Useful Links Icon Link" />
          </ListItemIcon>
          <ListItemText primary="Useful Links" />
        </ListItem>

        {(isForecaster() || isAdmin()) &&
          <div>
            <ListItem button component="a" target="_blank" href={adminUrl}>
              <ListItemIcon>
                <SupervisorAccountIcon titleAccess="Admin Console Icon Link" />
              </ListItemIcon>
              <ListItemText primary="Admin Console" />
            </ListItem>

            <ListItem button component="a" target="_blank">
              <ListItemIcon>
                <AccountTreeIcon titleAccess="Job Manager Icon Link" />
              </ListItemIcon>
              <ListItemText primary="Job Manager" />
            </ListItem>
          </div>
        }
      </List>
      <Divider />
      <Typography className={classes.amanziText}>Powered by:</Typography>
      <img
        src={amanzi}
        alt="Amanzi a Product of RTI International"
        width={239}
      />
    </div>
  );

  const toolbarContent = (
    <>
      <img
        src={logo}
        alt="New Hampshire Department of Environmental Services Logo"
        width={115}
      />
      <Typography variant="h6" className={classes.title} noWrap>
        {isSmallScreen ? "NHDES" : "New Hampshire Department of Environmental Services"}
      </Typography>

      {
        isAuthenticated() ? (
          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              style={{ color: "black" }}
            >
              <AccountCircle />
              <Typography style={{ color: "black" }} className={classes.user}>
                {me.first_name}
              </Typography>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={openMenu}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>Profile</MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </div>
        ) : (
          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              style={{ color: "black" }}
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={openMenu}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose} button component={NavLink} to="/login">Login</MenuItem>
            </Menu>
          </div>
        )
      }
    </>
  );

  useEffect(() => {
    setDrawerOpen(!isSmallScreen);
  }, [isSmallScreen]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: drawerOpen,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={isSmallScreen ? handleSmallScreenDrawerToggle : handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, drawerOpen && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          {toolbarContent}
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer}>
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={isMobileOpen}
            onClose={handleSmallScreenDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            {drawerContent}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="persistent"
            open={drawerOpen}
          >
            <div className={classes.drawerHeader}>
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <Divider />
            {drawerContent}
          </Drawer>
        </Hidden>
      </nav>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: drawerOpen,
        })}
      >
        <div className={classes.drawerHeader} />
        {mainContent}
      </main>
    </div>
  );
}