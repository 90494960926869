import { useState, useEffect, useContext } from "react";
import { fileRoot } from "../services/api";
import { userActions } from "../reducers/userReducer.js";
import { UserContext } from "../providers/UserProvider";

const axios = require("axios");

export const useGetHelpDocList = () => {
  const { state, dispatch } = useContext(UserContext);
  const [fileList, setFileList] = useState([]);
  const [loadingList, setLoadingList] = useState(true);
  const [error, setError] = useState(false);
  const { token } = state.user;

  useEffect(() => {
    const fetchHelpDocFiles = async () => {
      setLoadingList(true);
      try {
        const res = await axios.get(fileRoot + "/documents/", {
          params: {
            document_group_code: "HELP_DOCS",
          },
          headers: token && {
            Authorization: `Bearer ${token.access}`,
          },
        });

        let { data } = res;
        setFileList(data);
        setLoadingList(false);
      } catch (err) {
        if (err.response.status === 401) {
          window.localStorage.removeItem("afs_token");
          window.localStorage.removeItem("afs_user");
          dispatch(userActions.setToken(null));
          dispatch(userActions.setMe(null));
          setError("Unauthorized");
          setLoadingList(false);
        } else if (err.response.status === 403) {
          setError(
            "You do not have the appropriate permissions to view / use this resource."
          );
          setLoadingList(false);
        } else {
          console.log(err);
          setError(err.message);
          setLoadingList(false);
        }
      }
    };

    fetchHelpDocFiles();
  }, 
  [dispatch, token]
  );

  return { fileList, loadingList, error };
};
