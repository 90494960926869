import { useContext } from "react";
import { UserContext } from "../providers/UserProvider";
import { userActions } from "../reducers/userReducer.js";
import { apiRoot } from "../services/api";
import decode from "jwt-decode";
const axios = require("axios");

export const useUsers = () => {
  const { dispatch, state } = useContext(UserContext);
  const { token, me } = state.user;

  const login = async (username, password) => {
    await axios
      .post(apiRoot + "/token/", {
        username: username,
        password: password,
      })
      .then(function (res) {
        window.localStorage.setItem("afs_token", res.data);
        dispatch(userActions.setToken(res.data));
        dispatch(userActions.setUserError(null));
        getMe(res.data);
      })
      .catch(function (err) {
        if (err.response.status === 401) {
          window.localStorage.removeItem("afs_token");
          window.localStorage.removeItem("afs_user");
          dispatch(userActions.setUserError("Incorrect email or password"));
          dispatch(userActions.setToken(null));
          dispatch(userActions.setMe(null));
        } else if (err.response.status === 403) {
          dispatch(
            userActions.setUserError(
              "You do not have the appropriate permissions to view / use this resource."
            )
          );
        } else {
          console.log(err);
          dispatch(
            userActions.setUserError("Sorry, an unhandled error occurred")
          );
          dispatch(userActions.setToken(null));
          dispatch(userActions.setMe(null));
          window.localStorage.removeItem("afs_token");
          window.localStorage.removeItem("afs_user");
        }
      });
  };

  const logout = (props) => {
    props.history.replace("/");
    dispatch(userActions.setToken(null));
    dispatch(userActions.setMe(null));
    window.localStorage.removeItem("afs_token");
    window.localStorage.removeItem("afs_user");
  };

  const getMe = async (token) => {
    if (!token) {
      token = JSON.parse(window.localStorage.getItem("afs_token"));
    }
    if (token) {
      await axios
        .get(apiRoot + "/users/me/", {
          headers: token && {
            Authorization: `Bearer ${token.access}`,
          },
        })
        .then((res) => {
          window.localStorage.setItem("afs_user", res.data);
          dispatch(userActions.setMe(res.data));
          dispatch(userActions.setUserError(null));
        })
        .catch((err) => {
          if (err.response.status === 401) {
            window.localStorage.removeItem("afs_token");
            window.localStorage.removeItem("afs_user");
            dispatch(userActions.setUserError("Unauthorized"));
            dispatch(userActions.setToken(null));
            dispatch(userActions.setMe(null));
          } else if (err.response.status === 403) {
            dispatch(
              userActions.setUserError(
                "You do not have the appropriate permissions to view / use this resource."
              )
            );
          } else {
            console.log(err);
            dispatch(
              userActions.setUserError("Sorry, an unhandled error occurred")
            );
            dispatch(userActions.setToken(null));
            dispatch(userActions.setMe(null));
            window.localStorage.removeItem("afs_token");
            window.localStorage.removeItem("afs_user");
          }
        });
    }
  };

  const isAuthenticated = () => {
    if (me) {
      return token && !isTokenExpired();
    } else {
      return false
    }
  };

  const isTokenExpired = () => {
    try {
      const decoded = decode(token);
      if (decoded.exp < (Date.now() / 1000)) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  };

  const isAdmin = () => {
    if (me) {
      return me.is_superuser;
    } else {
      return false;
    }
  };

  const isForecaster = () => {
    if (me) {
      let isForecasterRw = me.groups.findIndex((r) => r.name === "forecaster_rw") > -1;
      let isForecasterRo = me.groups.findIndex((r) => r.name === "forecaster_ro") > -1;
      return isForecasterRw || isForecasterRo;
    } else {
      return false;
    }
  };

  return {
    login,
    logout,
    getMe,
    isForecaster,
    isAuthenticated,
    isAdmin
  };
};
