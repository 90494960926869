import React, { useState } from "react";
import { Grid, Paper, makeStyles, useMediaQuery, } from "@material-ui/core";
import { LocationMap } from "../Mapping/LocationMap.jsx";
import { StationDetail } from "./StationDetail";
import { useGetStationList } from "../../hooks/useGetStationList";
import { StationListFull } from "./StationListFull.jsx";
import { StationMapListTabs } from "../Utils/StationMapListTabs";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(1)
    },
    textAlign: "center",
    color: theme.palette.text.secondary,
    width: "100%",
    overflow: "auto",
  },
  tabPanel: {
    height: `calc(100vh - 120px)`,
  },
}));

export const Stations = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [showMap, setShowMap] = useState(true);
  const { stationList } = useGetStationList();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleSetSelectedFeature = (feature) => {
    setSelectedFeature(feature);
    setShowMap(false)
  }

  const mapComponent = (
    <div className="home-map" role="img" aria-label="home page map">
      <LocationMap
        locationList={stationList}
        setSelectedFeature={handleSetSelectedFeature}
      />
    </div>
  );

  const mapComponentMobile = (
    <div className="home-map-mobile" role="img" aria-label="home page map">
      <LocationMap
        locationList={stationList}
        setSelectedFeature={handleSetSelectedFeature}
      />
    </div>
  );

  const listComponent = (
    <StationListFull handleClick={handleSetSelectedFeature} />
  );

  return (
    <div className={classes.root}>
      {!isSmallScreen && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={5} style={{ display: "flex" }}>
            <Paper className={classes.paper}>
              <StationMapListTabs
                mapComponent={mapComponent}
                listComponent={listComponent}
                defaultTab={"map"}
                classes={classes}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} md={7} style={{ display: "flex" }}>
            <Paper className={classes.paper}>
              {!selectedFeature ? (
                "Select a station to see data"
              ) : (
                <StationDetail feature={selectedFeature} />
              )}
            </Paper>
          </Grid>
        </Grid>
      )}

      {isSmallScreen && (
        <div>

          {showMap ? (
            <StationMapListTabs
              mapComponent={mapComponentMobile}
              listComponent={listComponent}
              defaultTab={"map"}
              classes={classes}
            />
          ) : (
            <div>
              {!selectedFeature ? (
                "Select a station to see data"
              ) : (
                <StationDetail
                  feature={selectedFeature}
                  setShowMap={setShowMap}
                />
              )}
            </div>
          )

          }
        </div>
      )}

    </div>
  );
};
