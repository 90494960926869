import { useState, useEffect, useContext } from "react";
import { apiRoot } from "../services/api";
import { userActions } from "../reducers/userReducer.js";
import { UserContext } from "../providers/UserProvider";

const axios = require("axios");

export const useGetPlotConfigList = (feature) => {
  const { state, dispatch } = useContext(UserContext);
  const [plotConfigList, setPlotConfigList] = useState([]);
  const [loadingConfigList, setLoadingConfigList] = useState(true);
  const [getConfigListError, setGetConfigListError] = useState(false);
  const { token } = state.user;

  useEffect(() => {
    setGetConfigListError(null);

    const fetchPlotConfigs = async () => {
      if (feature) {
        setLoadingConfigList(true);
        try {
          const res = await axios.get(apiRoot + "/plots/", {
            params: {
              plot_group_code: "OBSERVATIONS",
              location: feature.id,
            },
            headers: token && {
              Authorization: `Bearer ${token.access}`,
            },
          });
          setPlotConfigList(res.data);
          setLoadingConfigList(false);
        } catch (err) {
          if (err.response.status === 401) {
            window.localStorage.removeItem("afs_token");
            window.localStorage.removeItem("afs_user");
            dispatch(userActions.setToken(null));
            dispatch(userActions.setMe(null));
            setGetConfigListError("Unauthorized");
            setLoadingConfigList(false);
          } else if (err.response.status === 403) {
            setGetConfigListError(
              "You do not have the appropriate permissions to view / use this resource."
            );
            setLoadingConfigList(false);
          } else {
            console.log(err);
            setGetConfigListError(err.message);
            setLoadingConfigList(false);
          }
        }
      }
    };

    setPlotConfigList(null);
    fetchPlotConfigs();
  }, 
  [feature, dispatch, token]
  );

  return { plotConfigs: plotConfigList, loadingConfigs: loadingConfigList, getConfigsError: getConfigListError };
};
