import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  List,
  CircularProgress,
} from "@material-ui/core";
import { useGetHelpDocList } from "../../hooks/useGetHelpDocs"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  secondaryBar: {
    display: "flex",
    marginBottom: theme.spacing(1),
  },
  paper: {
    display: "flex-grow",
  },
  body: {
    paddingTop: theme.spacing(2),
    textAlign: 'left',
  }
}));

export const HelpDocs = () => {
  const classes = useStyles();
  const { fileList, loadingList, error } = useGetHelpDocList();
  return (
    <div className={classes.root}>
      {loadingList && <CircularProgress />}
      {error && <Typography>Error: {JSON.stringify(error)}</Typography>}
      <List>
        {fileList.map((file, index) => {
          return (
            <Typography className={classes.body}>
              {file.description}<span> </span><a target="_blank" rel="noopener noreferrer" href={file.file}>{file.name}</a>
            </Typography>
          );
        })}
      </List>
    </div>
  );
};
