import axios from "axios";

export const getResource = async (path, config) => {
  let response;
  try {
    response = await axios.get(path, config);
    return response;
  } catch (err) {
    if (err.response.status === 401) {
      window.localStorage.removeItem("afs_token");
      window.localStorage.removeItem("afs_user");
    } else {
      return err;
    }
  }
};

export const postData = async (path, payload, config) => {
  let response;
  try {
    response = await axios.post(path, payload, config);
    return response;
  } catch (err) {
    if (err.response.status === 401) {
      window.localStorage.removeItem("afs_token");
      window.localStorage.removeItem("afs_user");
    } else {
      return err;
    }
  }
};
