import React from "react";
import {
  List,
  ListItem,
  CircularProgress,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { useGetStationList } from "../../hooks/useGetStationList";

export const StationListFull = ({ handleClick }) => {
  const { stationList, loadingList, error } = useGetStationList();

  return (
    <>
      {loadingList && <CircularProgress />}
      <List dense>
        {stationList && !loadingList
          ? stationList.map((station) => {
              return (
                <ListItem
                  disableGutters
                  key={station.id}
                  onClick={() => handleClick(station)}
                  button
                >
                  <ListItemIcon>
                    <LocationOnIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={station.properties.code}
                    secondary={station.properties.name}
                  />
                </ListItem>
              );
            })
          : error}
      </List>
    </>
  );
};
