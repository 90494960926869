import React, { useState } from "react";
import { Tab, Tabs } from "@material-ui/core";
import { TabContext, TabPanel } from "@material-ui/lab";

export const StationMapListTabs = ({ mapComponent, listComponent, defaultTab, classes }) => {
  const [tabValue, setTabValue] = useState(defaultTab);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div className={classes.root}>
      <TabContext value={tabValue}>
        {defaultTab === "map" ?
          <Tabs
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="black"
            variant="fullWidth"
            aria-label="full width tabs"
            value={tabValue}
          >
            <Tab label="Map" value={"map"} />
            <Tab label="List" value={"list"} />
          </Tabs>
          :
          <Tabs
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="black"
            variant="fullWidth"
            aria-label="full width tabs"
            value={tabValue}
          >
            <Tab label="List" value={"list"} />
            <Tab label="Map" value={"map"} />
          </Tabs>
        }

        <TabPanel 
        style={{ overflowY: "auto" }} 
        value={"list"}
        className={classes.tabPanel}
        >
          {listComponent}
        </TabPanel>
        <TabPanel
          style={{ padding: "0px" }}
          value={"map"}
          className={classes.tabPanel}
        >
          {mapComponent}
        </TabPanel>
      </TabContext>
    </div>
  );
};
