import React, { useState, useEffect, useContext } from "react";
import {
  Grid,
  Paper,
  makeStyles,
  CircularProgress,
  Typography,
  Toolbar,
  AppBar,
  Divider,
  IconButton,
} from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import axios from "axios";
import { DateTime } from "luxon";
import { MapContainer, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { UserContext } from "../../providers/UserProvider";
import { apiRoot, tileRoot } from "../../services/api";
import { userActions } from "../../reducers/userReducer.js";
import { NodeSelector } from "../Utils/NodeSelector";
import legend from "../../images/Legend.png";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: `calc(100vh - 150px)`,
    overflow: `auto`,
  },

  typography: {
    padding: theme.spacing(1),
  },
  popover: {
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(1),
  },
  tabPanel: {
    padding: "0px",
  },
  mainList: {
    width: "100%",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  forwardBackwardTiles: {
    display: "flex",
  },
}));

export const PrecipTilesContainer = () => {
  const classes = useStyles();

  const { state, dispatch } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [selectedSource, setSelectedSource] = useState("");
  const [selectedForecast, setSelectedForecast] = useState("");
  const [tileList, setTileList] = useState([]);
  const [tile, setTile] = useState("");
  const [selectedTimeseries, setSelectedTimeseries] = useState(null);

  const lat = 44;
  const lng = -71.5;
  const zoom = 7;
  const position = [lat, lng];

  useEffect(() => {
    if (!selectedSource) {
      setSelectedForecast("");
      setTileList([]);
      setTile("");
      setSelectedTimeseries(null);
    }
  }, [selectedSource]);

  useEffect(() => {
    if (selectedSource && selectedSource.name === "MRMS") {
      setSelectedForecast("");
      setSelectedTimeseries(null);
    }
  }, [selectedSource]);

  useEffect(() => {
    const fetchTimeseriesValues = async (id) => {
      setLoading(true);
      try {
        const results = await axios.get(`${apiRoot}/timeseries/${id}/values/`, {
          headers: state.user.token.access && {
            Authorization: `Bearer ${state.user.token.access}`,
          },
        });

        setTileList(results.data);
        setTile(results.data[0]);
        setLoading(false);
      } catch (err) {
        console.log(err);
        if (err.response?.status === 401) {
          window.localStorage.removeItem("afs_token");
          window.localStorage.removeItem("afs_user");
          dispatch(userActions.setToken(null));
          dispatch(userActions.setMe(null));
          setError("Unauthorized");
          setLoading(false);
        } else if (err.response.status === 403) {
          setError(
            "You do not have the appropriate permissions to view / use this resource."
          );
          setLoading(false);
        } else {
          console.log(err);
          setError(err.message);
          setLoading(false);
        }
      }
    };

    setTileList([]);
    setTile("");
    selectedTimeseries && fetchTimeseriesValues(selectedTimeseries.id);
  }, [selectedTimeseries, dispatch, state.user.token.access]);

  const forwardTile = () => {
    const currentIndex = tileList.findIndex(
      (currentTile) => tile.id === currentTile.id
    );

    // advance to next tile
    currentIndex < tileList.length - 1 && setTile(tileList[currentIndex + 1]);
    currentIndex === tileList.length - 1 && setTile(tileList[0]);
  };

  const reverseTile = () => {
    const currentIndex = tileList.findIndex(
      (currentTile) => tile.id === currentTile.id
    );

    // reverse to previous tile
    currentIndex !== 0 && setTile(tileList[currentIndex - 1]);
    currentIndex === 0 && setTile(tileList[tileList.length - 1]);
  };

  return (
    <div className={classes.root}>
      <AppBar className={classes.forecastBar} position="static" color="default">
        <Toolbar>
          <Typography>Source: </Typography>

          <NodeSelector
            selectedNode={selectedSource}
            setSelectedNode={setSelectedSource}
            url={apiRoot + "/tree_nodes/"}
            params={{ name: "NBM,MRMS" }}
            defaultSelected={0}
          />

          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            className={classes.divider}
          />
          {selectedSource && selectedSource.name === "NBM" ? (
            <>
              <Typography>Forecast: </Typography>

              <NodeSelector
                selectedNode={selectedForecast}
                setSelectedNode={setSelectedForecast}
                url={
                  apiRoot + "/tree_nodes/" + selectedSource.id + "/children/"
                }
                params={{}}
                defaultSelected={"end"}
              />
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                className={classes.divider}
              />
            </>
          ) : null}

          <Typography>Timeseries: </Typography>
          {!selectedForecast && selectedSource && (
            <NodeSelector
              selectedNode={selectedTimeseries}
              setSelectedNode={setSelectedTimeseries}
              url={apiRoot + "/timeseries/"}
              params={{
                tree_node: selectedSource.id,
                parameter__code: "GRQPF",
              }}
              defaultSelected={0}
            />
          )}

          {selectedForecast && (
            <NodeSelector
              selectedNode={selectedTimeseries}
              setSelectedNode={setSelectedTimeseries}
              url={apiRoot + "/timeseries/"}
              params={{
                tree_node: selectedForecast.id,
                parameter__code: "GRQPF",
              }}
              defaultSelected={0}
            />
          )}

          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            className={classes.divider}
          />
          {tile ? (
            <div className={classes.forwardBackwardTiles}>
              <IconButton onClick={reverseTile}>
                <ArrowBackIcon />
              </IconButton>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Typography>
                  {DateTime.fromISO(tile.datetime).toFormat("y-LL-dd")}
                </Typography>
                <Typography>
                  {DateTime.fromISO(tile.datetime).toFormat("HH:mm")}{" "}
                </Typography>
              </div>
              <IconButton onClick={forwardTile}>
                <ArrowForwardIcon />
              </IconButton>
            </div>
          ) : (
            <Typography>No Tiles Loaded</Typography>
          )}
        </Toolbar>
      </AppBar>
      {loading && <CircularProgress />}
      {!loading && (
        <Grid container spacing={1}>
          <Grid item sm={10}>
            <Paper className={classes.paper}>
              {error && <Typography>{error}</Typography>}
              {!error && (
                <div
                  className="tiles-map"
                  role="img"
                  aria-label="gridded data precip tiles map"
                >
                  <MapContainer center={position} zoom={zoom}>
                    <TileLayer
                      attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <TileLayer
                      key={tile.char_value}
                      attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                      url={`${tileRoot}/${tile.char_value}/{z}/{x}/{-y}.png`}
                      minNativeZoom={6}
                      maxNativeZoom={6}
                      opacity={0.8}
                    />
                  </MapContainer>
                </div>
              )}
            </Paper>
          </Grid>
          <Grid item sm={2}>
            <Paper className={classes.paper}>
              <Typography>Legend</Typography>
              <img
                src={legend}
                alt="gridded data precip tiles map legend"
                style={{ maxHeight: 500 }}
              />
            </Paper>
          </Grid>
        </Grid>
      )}
    </div>
  );
};
