import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { CircularProgress } from "@material-ui/core";
import { apiRoot } from "../../services/api";
import { UserContext } from "../../providers/UserProvider";
import { userActions } from "../../reducers/userReducer.js";
import { DataTreeView } from "./DataTreeView";

export const Tree = ({
  selectedNodeId,
  setSelectedNodeId,
  expanded,
  setExpanded,
}) => {
  const { state, dispatch } = useContext(UserContext);
  const { token } = state.user;
  const [hasError, setErrors] = useState(false);
  const [treeData, setTreeData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTreeData = async () => {
      setLoading(true);
      try {
        const res = await axios.get(apiRoot + "/tree_nodes/tree/", {
          params: {
            format: "json",
            enabled: true,
          },
          headers: token && {
            Authorization: `Bearer ${token.access}`,
          },
        });
        setTreeData(res.data);
        setLoading(false);
      } catch (err) {
        if (err.response.status === 401) {
          window.localStorage.removeItem("afs_token");
          window.localStorage.removeItem("afs_user");
          dispatch(userActions.setToken(null));
          dispatch(userActions.setMe(null));
          setErrors("Unauthorized");
          setLoading(false);
        } else if (err.response.status === 403) {
          setErrors(
            "You do not have the appropriate permissions to view / use this resource."
          );
          setLoading(false);
        } else {
          console.log(err);
          setErrors(err.message);
          setLoading(false);
        }
      }
    };
    fetchTreeData();
  }, [dispatch, token]);

  return (
    <>
      {loading && <CircularProgress size={70} data-testid="loader" />}
      {!loading && (
        <DataTreeView
          treeItems={treeData}
          selectedNodeId={selectedNodeId}
          setSelectedNodeId={setSelectedNodeId}
          expanded={expanded}
          setExpanded={setExpanded}
          hasError={hasError}
        />
      )}
    </>
  );
};
