import React, { useState, useEffect } from "react";

import {
  AppBar,
  FormControl,
  NativeSelect,
  Toolbar,
  IconButton,
  Tabs,
  Tab,
  makeStyles,
  Typography,
  Box,
  CircularProgress,
} from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import PropTypes from "prop-types";
import DatetimeSelector from "../Utils/DateTimeSelector";
import { DateTime } from "luxon";
import { ConfigurationPlotTable } from "./ConfigurationPlotTable";
import { ConfigurationPlot } from "./ConfigurationPlot";
import { useGetPlotConfigList } from "../../hooks/useGetPlotConfigList";
import { useGetPlotConfig } from "../../hooks/useGetPlotConfig";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  forecastBar: {
    display: "flex",
    marginBottom: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: "575px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    justify: "right",
  },
  grow: {
    flexGrow: 1,
  },
  forecastApproval: {
    marginLeft: "auto",
  },
  forecastDetail: {
    marginLeft: "auto",
  },
  typography: {
    padding: theme.spacing(2),
  },
  popover: {
    padding: theme.spacing(2),
  },
  adjustTimeButton: {
    marginLeft: "auto",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className={classes.tabPanel}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export const StationPlot = ({ feature }) => {
  const { plotConfigs, loadingConfigs, getConfigsError } = useGetPlotConfigList(feature);
  const [selectedPlotConfig, setSelectedPlotConfig] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [dateSelectorOpen, setDateSelectorOpen] = useState(false);
  const [startDatetime, setStartDatetime] = useState(
    DateTime.now().minus({ days: 30 })
  );
  const [endDatetime, setEndDatetime] = useState(
	  DateTime.now().plus({ days: 3 })
  );
  const { plotConfig, loadingPlotConfig, getPlotConfigError } = useGetPlotConfig(
    selectedPlotConfig, feature, startDatetime, endDatetime
  );
  const classes = useStyles();

  const handleDateSelectorOpen = () => {
    setDateSelectorOpen(true);
  };

  const handleDateSelectorClose = () => {
    setDateSelectorOpen(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handlePlotChange = (event) => {
    if(event.target.value)
      setSelectedPlotConfig(
        plotConfigs.find((node) => node.id === event.target.value)
      );
  };

  const PlotSelection = () => {
    return (
      <AppBar className={classes.forecastBar} position="static" color="default">
        <Toolbar>
          <FormControl>
            <NativeSelect
              value={selectedPlotConfig ? selectedPlotConfig.id : ""}
              onChange={handlePlotChange}
              inputProps={{
                name: "plot",
                id: "plot-select",
              }}
            >
              <option aria-label="None" value="" />
              {plotConfigs.map((value, index) => {
                return (
                  <option key={index} value={value.id}>
                    {value.name}
                  </option>
                );
              })}
            </NativeSelect>
          </FormControl>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleDateSelectorOpen}
            color="primary"
            className={classes.adjustTimeButton}
          >
            <AccessTimeIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    );
  };

  useEffect(() => {
    if (plotConfigs) {
      setSelectedPlotConfig(plotConfigs[0]);
    }
  }, [plotConfigs]);

  return (
    <>
      {!loadingConfigs ? (
        !(getConfigsError || getPlotConfigError) ? (
          plotConfigs.length > 0 ? (
            <>
              <PlotSelection />

              <AppBar position="static" color="default">
                <Tabs
                  value={tabIndex}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  textColor="black"
                  variant="fullWidth"
                  aria-label="timeseries tabs"
                >
                  <Tab label="Plot" {...a11yProps(0)} />
                  <Tab label="Table" {...a11yProps(1)} />
                </Tabs>
              </AppBar>

              {!loadingPlotConfig ? (
                <>
                  <TabPanel value={tabIndex} index={0}>
                    <ConfigurationPlot
                      config={plotConfig}
                      plotConfig={selectedPlotConfig}
                    />
                  </TabPanel>
                  <TabPanel value={tabIndex} index={1}>
                    <ConfigurationPlotTable config={plotConfig} />
                  </TabPanel>
                </>
              ) : (
                <CircularProgress />
              )}
            </>
          ) : (
            <Typography>
              No plots configured for {feature.properties.name} (
              {feature.properties.code}).
            </Typography>
          )
        ) : (
          <Typography>
            Error: {JSON.stringify(getConfigsError)}
            Error: {JSON.stringify(getPlotConfigError)}
          </Typography>
        )
      ) : (
        <CircularProgress />
      )}

      <DatetimeSelector
        open={dateSelectorOpen}
        setOpen={setDateSelectorOpen}
        handleClose={handleDateSelectorClose}
        startDatetime={startDatetime}
        setStartDatetime={setStartDatetime}
        endDatetime={endDatetime}
        setEndDatetime={setEndDatetime}
      />
    </>
  );
};
