import React, { useState, useEffect, useContext } from "react";
import {
  Grid,
  Paper,
  AppBar,
  FormControl,
  NativeSelect,
  Toolbar,
  LinearProgress,
  Typography,
  Collapse,
  useMediaQuery,
  makeStyles
} from "@material-ui/core";
import axios from "axios";
import { apiRoot } from "../../services/api";
import { UserContext } from "../../providers/UserProvider";
import { userActions } from "../../reducers/userReducer.js";
import { ForecastPlot } from "./ForecastPlot";
import { ForecastMap } from "./ForecastMap";
import { ForecastStyles } from "./ForecastStyles";
import { NodeSelector } from "../Utils/NodeSelector";
import { ModelStatesSettings } from "../ModelStates/ModelStatesSettings";
import { formatForecastDateTime } from "../../helpers/helpers";
import { ModelStatesContainer } from "../ModelStates/ModelStatesContainer";
import { StationMapListTabs } from "../Utils/StationMapListTabs";
import { ForecastStationList } from "./ForecastStationList";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  tabPanel: {
    height: `calc(100vh - 300px)`,
  },
}));

export const Forecasts = () => {
  const { dispatch, state } = useContext(UserContext);
  const theme = useTheme();
  const classes = ForecastStyles();
  const tabClasses = useStyles();
  const { token } = state.user;
  const [showMap, setShowMap] = useState(true);
  const [hasError, setErrors] = useState(false);
  const [loading, setLoading] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [selectedBasinNode, setSelectedBasinNode] = useState(null);
  const [selectedScenarioNode, setSelectedScenarioNode] = useState(null);
  const [selectedForecastNode, setSelectedForecastNode] = useState(null);
  const [forecastNodes, setForecastNodes] = useState([]);
  const [modelStatesCheck, setModelStatesCheck] = useState(null);
  const [mapCheck, setMapCheck] = useState(true);
  const [modelStatesTS, setModelStatesTS] = useState([]);
  const [forecastSourceNode] = useState({
    id: "d3c4d3ca-1dcf-4412-852e-dcd9bc2e4579",
    name: "NHDES-RT",
    enabled: true,
    tree_node_type: {
      id: "9fcce7ee-221e-4056-bec6-720b4d0c9f30",
      name: "Source",
      description: null,
      enabled: true,
    },
    level: 0,
    forecast: null,
    source: {
      id: "5491a671-14bf-4705-a3be-c4b31c26a35d",
      name: "NHDES general input",
      code: "NHDES",
      enabled: true,
      description: "Allows overwrites of model states",
      source_type: "d588bfa1-7124-4aa8-8e4a-e8bb7eead9ee",
      tree_node: "d3c4d3ca-1dcf-4412-852e-dcd9bc2e4579",
    },
    scenario: null,
  });

  const handleChange = (event) => {
    setSelectedForecastNode(
      forecastNodes.find((node) => node.id === event.target.value)
    );
  };

  useEffect(() => {
    const fetchLatestForecast = async () => {
      try {
        if (selectedScenarioNode) {
          setLoading(true);
          const res = await axios.get(
            apiRoot + "/tree_nodes/" + selectedScenarioNode.id + "/children/",
            {
              params: {
                forecast__status__code: "SUCCESS",
              },
              headers: token && {
                Authorization: `Bearer ${token.access}`,
              },
            }
          );
          const { data } = res;
          if (data.length > 0) {
            setSelectedForecastNode(data[data.length - 1]);
            setForecastNodes(data.reverse());
            setLoading(false);
          }
        }
      } catch (err) {
        if (err.response.status === 401) {
          window.localStorage.removeItem("afs_token");
          window.localStorage.removeItem("afs_user");
          dispatch(userActions.setToken(null));
          dispatch(userActions.setMe(null));
          setErrors("Unauthorized");
          setLoading(false);
        } else if (err.response.status === 403) {
          setErrors(
            "You do not have the appropriate permissions to view / use this resource."
          );
          setLoading(false);
        } else {
          console.log(err);
          setErrors(err.message);
          setLoading(false);
        }
      }
    };

    fetchLatestForecast();
  }, [selectedScenarioNode, dispatch, token]);

  useEffect(() => {
    setSelectedFeature(null);
  }, [selectedBasinNode]);

  useEffect(() => {
    const fetchModelStatesTS = async () => {
      setLoading(true);
      try {
        const res = await axios.get(apiRoot + `/timeseries/`, {
          params: {
            location__code: selectedFeature.properties.code,
            tree_node: selectedScenarioNode.id,
            parameter__code: "SNWE,UZTWC,UZFWC,LZFPC,LZFSC,LZTWC",
            format: "json",
            ordering: "-order",
          },
          headers: state.user.token.access && {
            Authorization: `Bearer ${state.user.token.access}`,
          },
        });
        const { data } = res;
        setModelStatesTS(data);
        setLoading(false);
      } catch (err) {
        if (err.response.status === 401) {
          window.localStorage.removeItem("afs_token");
          window.localStorage.removeItem("afs_user");
          dispatch(userActions.setToken(null));
          dispatch(userActions.setMe(null));
          setErrors("Unauthorized");
          setLoading(false);
        } else if (err.response.status === 403) {
          setErrors(
            "You do not have the appropriate permissions to view / use this resource."
          );
          setLoading(false);
        } else {
          console.log(err);
          setErrors(err.message);
          setLoading(false);
        }
      }
    };

    selectedFeature && selectedScenarioNode && fetchModelStatesTS();
  }, [
    selectedFeature,
    selectedScenarioNode,
    dispatch,
    state.user.token.access,
  ]);

  const SelectForecast = () => {
    return (
      <>
        {!loading ? (
          !hasError ? (
            <FormControl className={classes.formControl}>
              <NativeSelect
                value={selectedForecastNode ? selectedForecastNode.id : ""}
                onChange={handleChange}
                inputProps={{
                  name: "forecast",
                  id: "forecast-select",
                }}
              >
                <option aria-label="None" value="" />
                {forecastNodes.map((value, index) => {
                  return (
                    <option key={index} value={value.id}>
                      {formatForecastDateTime(value.forecast.valid_at)}
                    </option>
                  );
                })}
              </NativeSelect>
            </FormControl>
          ) : (
            <Typography>Error: {JSON.stringify(hasError)}</Typography>
          )
        ) : (
          <LinearProgress />
        )}
      </>
    );
  };

  const handleModelStatesCheck = () => {
    setModelStatesCheck(modelStatesCheck ? false : true);
  };

  const handleMapCheck = () => {
    setMapCheck(mapCheck ? false : true);
  };

  const handleSetSelectedFeature = (feature) => {
    setSelectedFeature(feature);
    setShowMap(false)
  }

  const mapComponent = (
    <div className="forecast-map" role="img" aria-label="forecast page map">
      <ForecastMap
        setSelectedFeature={handleSetSelectedFeature}
        selectedBasinNode={selectedBasinNode}
        selectedFeature={selectedFeature}
      />
    </div>
  );

  const mapComponentMobile = (
    <div className="forecast-map-mobile" role="img" aria-label="forecast page map">
      <ForecastMap
        setSelectedFeature={handleSetSelectedFeature}
        selectedBasinNode={selectedBasinNode}
        selectedFeature={selectedFeature}
      />
    </div>
  );

  const listComponent = (
    <ForecastStationList
      handleClick={handleSetSelectedFeature}
      selectedBasinNode={selectedBasinNode}
    />
  );

  return (
    <div className={classes.root}>

      {(!isSmallScreen || (isSmallScreen && showMap)) && (
        <Grid container>
          <Grid item xs={12}>
            <AppBar className={classes.forecastBar} position="static" color="default">
              <Toolbar>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12} lg={3} className={classes.forecastBarGridItems}>
                    <Typography noWrap>Basin: </Typography>
                    {forecastSourceNode && (
                      <NodeSelector
                        selectedNode={selectedBasinNode}
                        setSelectedNode={setSelectedBasinNode}
                        url={
                          apiRoot + "/tree_nodes/" + forecastSourceNode.id + "/children/"
                        }
                        params={{}}
                        defaultSelected={0}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={4} className={classes.forecastBarGridItems}>
                    <Typography noWrap>Scenario: </Typography>
                    {selectedBasinNode && (
                      <NodeSelector
                        selectedNode={selectedScenarioNode}
                        setSelectedNode={setSelectedScenarioNode}
                        url={
                          apiRoot + "/tree_nodes/" + selectedBasinNode.id + "/children/"
                        }
                        params={{}}
                        defaultSelected={0}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={4} className={classes.forecastBarGridItems}>
                    <Typography noWrap>Forecast: </Typography>
                    {selectedScenarioNode && <SelectForecast />}
                  </Grid>
                </Grid>

                <div className={classes.grow} />
                {!isSmallScreen && (
                  <div className={classes.forecastDetail}>
                    <ModelStatesSettings
                      handleMapCheck={handleMapCheck}
                      handleModelStatesCheck={handleModelStatesCheck}
                      mapCheck={mapCheck}
                      modelStatesCheck={modelStatesCheck}
                    />
                  </div>
                )}
              </Toolbar>
            </AppBar>
          </Grid>
        </Grid>
      )}

      {!isSmallScreen && (
        <Grid container>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid
                item
                xs={12}
                sm={12}
                md={
                  (!mapCheck && 0) ||
                  (mapCheck && modelStatesCheck && 3) ||
                  (mapCheck && !modelStatesCheck && 3)
                }
              >
                <Collapse in={mapCheck}>
                  <Paper className={classes.paper}>
                    <StationMapListTabs
                      mapComponent={mapComponent}
                      listComponent={listComponent}
                      defaultTab={"list"}
                      classes={classes}
                    />
                  </Paper>
                </Collapse>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={
                  (!mapCheck && !modelStatesCheck && 12) ||
                  (!mapCheck && modelStatesCheck && 6) ||
                  (mapCheck && modelStatesCheck && 4) ||
                  (mapCheck && !modelStatesCheck && 9)
                }
              >
                <Paper className={classes.paper}>
                  {!selectedFeature || !selectedForecastNode ? (
                    "Select basin to see plot"
                  ) : (
                    <ForecastPlot
                      feature={selectedFeature}
                      forecast={selectedForecastNode.forecast}
                      scenario={selectedScenarioNode.scenario}
                      setShowMap={setShowMap}
                    />
                  )}
                </Paper>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={
                  (!modelStatesCheck && 0) ||
                  (!mapCheck && modelStatesCheck && 6) ||
                  (mapCheck && modelStatesCheck && 5)
                }
              >
                <Collapse in={modelStatesCheck}>
                  <Paper className={classes.paper}>
                    {!selectedFeature || !selectedForecastNode ? (
                      "Select basin to see plot"
                    ) : (
                      <ModelStatesContainer
                        timeseriesList={modelStatesTS}
                        feature={selectedFeature}
                      />
                    )}
                  </Paper>
                </Collapse>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}

      {isSmallScreen && (
        <div>

          {showMap ? (
            <StationMapListTabs
              mapComponent={mapComponentMobile}
              listComponent={listComponent}
              defaultTab={"list"}
              classes={tabClasses}
            />
          ) : (
            <>
              {!selectedFeature || !selectedForecastNode ? (
                "Select basin to see plot"
              ) : (
                <ForecastPlot
                  feature={selectedFeature}
                  forecast={selectedForecastNode.forecast}
                  scenario={selectedScenarioNode.scenario}
                  setShowMap={setShowMap}
                />
              )}
            </>
          )

          }
        </div>
      )}

    </div>
  );
};
