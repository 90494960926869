import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import {
  formatAlertDateTime,
  alertLogicTranslator,
} from "../../helpers/helpers";

export const AlertItem = ({ alert }) => {
  return (
    <Grid
      container
      alignItems="center"
      style={{
        marginLeft: "2%",
        marginRight: "2%",
        justifyContent: "space-between",
      }}
    >
      <Grid item xs={12} md={2} className="alert-detail">
        {alert?.timeseries.location.name} ({alert?.timeseries.location.code})
      </Grid>
      <Grid item xs={12} md={2} className="alert-detail">
        {alert?.timeseries.name}
      </Grid>
      <Grid item xs={12} md={2} className="alert-detail">
        {alert?.threshold.name}:{" "}
        {alertLogicTranslator(alert?.threshold_alert.logic)}{" "}
        {alert?.threshold.value} {alert?.timeseries.parameter.unit.code}
      </Grid>
      <Grid item xs={12} md={2} className="alert-detail">
        {Math.round(alert?.timeseriesvalue.num_value)}{" "}
        {alert?.timeseries.parameter.unit.code}
      </Grid>
      <Grid item xs={12} md={2} className="alert-detail">
        {formatAlertDateTime(alert?.timeseriesvalue.datetime)}
      </Grid>
    </Grid>
  );
};

AlertItem.propTypes = {
  alert: PropTypes.shape({
    active: PropTypes.bool,
    description: PropTypes.string,
    id: PropTypes.string,
    threshold: PropTypes.shape({
      id: PropTypes.string,
      code: PropTypes.string,
      description: PropTypes.string,
      enabled: PropTypes.bool,
      location: PropTypes.string,
      name: PropTypes.string,
      parameter: PropTypes.string,
      value: PropTypes.number,
    }),
    threshold_alert: PropTypes.shape({
      id: PropTypes.string,
      logic: PropTypes.string,
      threshold: PropTypes.string,
    }),
    timeseries: PropTypes.object,
    timeseriesvalue: PropTypes.shape({
      char_value: PropTypes.number,
      datetime: PropTypes.string,
      id: PropTypes.string,
      num_value: PropTypes.number,
      timeseries: PropTypes.string,
    }),
  }),
};
