import React, { useState, useEffect, useRef, useContext } from "react";
import "leaflet/dist/leaflet.css";
import axios from "axios";
import Control from 'react-leaflet-custom-control'
import {
  Typography,
  Button,
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { MapContainer, TileLayer, GeoJSON } from "react-leaflet";
import { apiRoot } from "../../services/api";
import { UserContext } from "../../providers/UserProvider";
import { userActions } from "../../reducers/userReducer.js";
import { StationGeoJSON } from "./StationGeoJSON";
import legend from "../../images/station_legend.png";
import "../../css/Map.css";

export const LocationMap = ({ setSelectedFeature, locationList }) => {
  const { state, dispatch } = useContext(UserContext);
  const { token } = state.user;

  const [hasError, setErrors] = useState(false);
  const [stations, setStations] = useState({});
  const [basins, setBasins] = useState({});
  const [huc, setHuc] = useState({});
  const [legendOpen, setLegendOpen] = useState(false);

  const lat = 44;
  const lng = -71.5;
  const zoom = 8;
  const position = [lat, lng];

  const handleLegendOpen = () => setLegendOpen(true);
  const handleLegendClose = () => setLegendOpen(false);

  useEffect(() => {
    !hasError && setStations(locationList);
    hasError && setErrors(hasError);
  }, [hasError, locationList]);

  useEffect(() => {
    async function fetchData() {
      axios
        .get(apiRoot + "/locations/", {
          params: {
            layer__code: "HUC10",
          },
          headers: token && {
            Authorization: `Bearer ${token.access}`,
          },
        })
        .then((res) => {
          setHuc(res.data);
        })
        .catch(function (err) {
          if (err.response.status === 401) {
            window.localStorage.removeItem("afs_token");
            window.localStorage.removeItem("afs_user");
            dispatch(userActions.setToken(null));
            dispatch(userActions.setMe(null));
            setErrors("Unauthorized");
          } else if (err.response.status === 403) {
            setErrors(
              "You do not have the appropriate permissions to view / use this resource."
            );
          } else {
            console.log(err);
            setErrors(err.message);
          }
        });
    }

    fetchData();
  }, [dispatch, token]);

  useEffect(() => {
    async function fetchBasins() {
      axios
        .get(apiRoot + "/locations/", {
          params: {
            layer__code: "BASINS",
          },
          headers: token && {
            Authorization: `Bearer ${token.access}`,
          },
        })
        .then((res) => {
          setBasins(res.data);
        })
        .catch(function (err) {
          if (err.response.status === 401) {
            window.localStorage.removeItem("afs_token");
            window.localStorage.removeItem("afs_user");
            dispatch(userActions.setToken(null));
            dispatch(userActions.setMe(null));
            setErrors("Unauthorized");
          } else if (err.response.status === 403) {
            setErrors(
              "You do not have the appropriate permissions to view / use this resource."
            );
          } else {
            console.log(err);
            setErrors(err.message);
          }
        });
    }

    fetchBasins();
  }, [dispatch, token]);

  const basinStyle = (feature, layer) => {
    let defaultStyle = {
      fillColor: "blue",
      fillOpacity: 0.5,
      color: "black",
      weight: 0.5,
      opacity: 1,
    };

    if (feature.properties.code === "WINNI_B") {
      defaultStyle.fillColor = "#CDAB65";
    } else if (feature.properties.code === "Smith Basin") {
      defaultStyle.fillColor = "#D9B09E";
    } else if (feature.properties.code === "PEMI_B") {
      defaultStyle.fillColor = "#E59800";
    } else if (feature.properties.code === "OSSI_B") {
      defaultStyle.fillColor = "#FFCC65";
    } else if (feature.properties.code === "SALMF_B") {
      defaultStyle.fillColor = "#D7D89F";
    } else if (feature.properties.code === "SUNC_B") {
      defaultStyle.fillColor = "#838441";
    } else if (feature.properties.code === "MASC_B") {
      defaultStyle.fillColor = "#CECF65";
    } else if (feature.properties.code === "PISC_B") {
      defaultStyle.fillColor = "#B4B4B2";
    } else if (feature.properties.code === "POW_B") {
      defaultStyle.fillColor = "#B7C7C6";
    } else if (feature.properties.code === "SOUC_B") {
      defaultStyle.fillColor = "#79D601";
    } else if (feature.properties.code === "LAMP_B") {
      defaultStyle.fillColor = "#E29174";
    } else if (feature.properties.code === "EEXT_B") {
      defaultStyle.fillColor = "#F4E800";
    } else if (feature.properties.code === "SUGR_B") {
      defaultStyle.fillColor = "#C587F4";
    } else if (feature.properties.code === "CONT_B") {
      defaultStyle.fillColor = "#A83800";
    } else if (feature.properties.code === "SOUH_B") {
      defaultStyle.fillColor = "#CD6799";
    }

    return defaultStyle;
  };

  const hucStyle = {
    fillColor: "grey",
    fillOpacity: 0.25,
    weight: 0.5,
    color: "blue",
    opacity: 0.5,
    // 'fill': false,
  };

  const basinRef = useRef();
  const hucRef = useRef();
  const stationsPointsRef = useRef();

  useEffect(() => {
    const reorderLayers = () => {
      if (stationsPointsRef.current) {
        basinRef.current.bringToFront();
        stationsPointsRef.current.bringToFront();
      }
    };
    reorderLayers();
  }, [basins, huc]);

  const onEachBasin = (feature, layer) => {
    layer.on({
      mouseover: () => {
        layer.bindTooltip(feature.properties.name, { direction: "center" });
        layer.openTooltip();
      },
      mouseout: () => {
        layer.closeTooltip();
      },
    });
  };

  return (
    <>
      {hasError && <Typography>{hasError}</Typography>}
      {!hasError && (
        <MapContainer center={position} zoom={zoom}>
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <StationGeoJSON
            geoJsonRef={stationsPointsRef}
            features={stations}
            setSelectedFeature={setSelectedFeature}
          />
          <GeoJSON
            ref={basinRef}
            key={basins.features}
            data={basins.features}
            style={basinStyle}
            onEachFeature={onEachBasin}
          />
          <GeoJSON
            ref={hucRef}
            key={huc.features}
            data={huc.features}
            style={hucStyle}
          />
          <Control prepend position='topright'>
            <Button variant="contained" size="small" onClick={handleLegendOpen}>
              Station Legend
            </Button>
          </Control>
        </MapContainer>
      )}

      <Dialog open={legendOpen} onClose={handleLegendClose} aria-labelledby="station-legend_dialog">
        <DialogTitle id="form-dialog-title">Station Legend</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The following legend describes the symbology used for the stations shown 
            in the map which indicated what type of data is available. Note, a station 
            can have mutiple types of data, in which case it will be represented by 
            multiple "stacked" symbols.
          </DialogContentText>
          <img
            src={legend}
            alt="Station legend describing station symbology"
            height="200"
          />

        </DialogContent>
        <DialogActions>
          <Button onClick={handleLegendClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>

  );
};
