import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import TimelineIcon from "@material-ui/icons/Timeline";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { apiRoot } from "../../services/api";
import { UserContext } from "../../providers/UserProvider";
import { userActions } from "../../reducers/userReducer.js";

const useStyles = makeStyles((theme) => ({
  list: {
    margin: theme.spacing(1),
    overflow: "auto",
    height: "600px",
  },
  text: {
    padding: theme.spacing(2),
  },
}));

export const TimeseriesList = ({ treeNodeId, setSelectedTimeseriesId }) => {
  const [hasError, setErrors] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timeseries, setTimeseries] = useState([]);

  const { state, dispatch } = useContext(UserContext);
  const { token } = state.user;
  const classes = useStyles();

  useEffect(() => {
    setTimeseries([]);
    setSelectedTimeseriesId(null);

    const fetchSparseTsData = async () => {
      try {
        const res = await axios.get(apiRoot + "/timeseries/sparse/", {
          params: {
            tree_node: treeNodeId,
          },
          headers: token && {
            Authorization: `Bearer ${token.access}`,
          },
        });
        setTimeseries(res.data);
        setLoading(false);
      } catch (err) {
        if (err.response.status === 401) {
          window.localStorage.removeItem("afs_token");
          window.localStorage.removeItem("afs_user");
          dispatch(userActions.setToken(null));
          dispatch(userActions.setMe(null));
          setErrors("Unauthorized");
          setLoading(false);
        } else if (err.response.status === 403) {
          setErrors(
            "You do not have the appropriate permissions to view / use this resource."
          );
          setLoading(false);
        } else {
          console.log(err);
          setErrors(err.message);
          setLoading(false);
        }
      }
    };

    if (treeNodeId) {
      setLoading(true);
      fetchSparseTsData();
    }
  }, [setSelectedTimeseriesId, treeNodeId, dispatch, token]);

  return (
    <>
      {treeNodeId ? (
        !loading ? (
          <List className={classes.list}>
            {!hasError ? (
              timeseries.map((s) => (
                <ListItem
                  dense={true}
                  key={s.id}
                  onClick={() => setSelectedTimeseriesId(s.id)}
                  button
                >
                  <ListItemIcon>
                    <TimelineIcon />
                  </ListItemIcon>
                  <ListItemText primary={s.code} />
                </ListItem>
              ))
            ) : (
              <Typography>Error: {JSON.stringify(hasError)}</Typography>
            )}
          </List>
        ) : (
          <CircularProgress />
        )
      ) : (
        <Typography className={classes.text}>
          Select a folder to see contents
        </Typography>
      )}
    </>
  );
};
