import React, { useState, useContext } from "react";
import {
  makeStyles,
  Paper,
  Typography,
  Button,
  TextField,
  Grid,
} from "@material-ui/core";
import { useUsers } from "../../hooks/userAPI";
import { UserContext } from "../../providers/UserProvider";

const useStyles = makeStyles((theme) => ({
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    margin: theme.spacing(2),
    maxWidth: 500,
    alignItems: "center",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: 300,
  },
  button: {
    margin: theme.spacing(),
  },
  error: {
    color: "red",
  },
}));

export const Login = (props) => {
  const classes = useStyles();
  const { login } = useUsers();
  const { state } = useContext(UserContext);
  const { error } = state.user;

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleUserNameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleFormSubmit = () => {
    login(username, password).then(() => {
      props.location.state
        ? props.history.push(props.location.state.from)
        : props.history.push("/");
    });
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      style={{ minHeight: "100vh" }}
      className={classes.grid}
    >
      <Grid>
        <Paper className={classes.paper} elevation={1}>
          <Typography variant="h6">State of New Hampshire</Typography>
          <Typography>Login to use the application.</Typography>
          <form className={classes.container} noValidate autoComplete="off">
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Grid item>
                <TextField
                  id="username"
                  label="Username"
                  className={classes.textField}
                  value={username}
                  onChange={handleUserNameChange}
                  margin="normal"
                  type="username"
                />
              </Grid>
              <Grid item>
                <TextField
                  id="password"
                  label="Password"
                  className={classes.textField}
                  type="password"
                  autoComplete="current-password"
                  margin="normal"
                  onChange={handlePasswordChange}
                  onKeyPress={(ev) => {
                    if (ev.key === "Enter") {
                      handleFormSubmit(ev);
                    }
                  }}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={handleFormSubmit}
                >
                  Login
                </Button>
              </Grid>
            </Grid>
          </form>
          {error && <Typography className={classes.error}>{error}</Typography>}
        </Paper>
      </Grid>
    </Grid>
  );
};
