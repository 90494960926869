import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, Grid, Paper, Typography } from "@material-ui/core";

import { Tree } from "./Tree";
import { TimeseriesList } from "./TimeseriesList";
import { TimeseriesDetailComp } from "./TimeseriesDetailComp";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  secondaryBar: {
    display: "flex",
    marginBottom: theme.spacing(1),
  },
  paper: {
    display: "flex-grow",
  },
}));

export const Browser = () => {
  const classes = useStyles();
  const [selectedNodeId, setSelectedNodeId] = useState(null);
  const [expanded, setExpanded] = useState([]);
  const [selectedTimeseriesId, setSelectedTimeseriesId] = useState(null);

  return (
    <div className={classes.root}>
      <AppBar
        className={classes.secondaryBar}
        position="static"
        color="default"
      >
        <Toolbar>
          <Typography variant="h6">Timeseries Browser</Typography>
        </Toolbar>
      </AppBar>

      <Paper className={classes.paper}>
        <Grid container direction="row" alignItems="stretch" spacing={1}>
          <Grid item xs={12} md={3}>
            <AppBar
              className={classes.secondaryBar}
              position="static"
              color="default"
            >
              <Toolbar>
                <Typography variant="h6">Folders</Typography>
              </Toolbar>
            </AppBar>
            <Tree
              selectedNodeId={selectedNodeId}
              setSelectedNodeId={setSelectedNodeId}
              expanded={expanded}
              setExpanded={setExpanded}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <AppBar
              className={classes.secondaryBar}
              position="static"
              color="default"
            >
              <Toolbar>
                <Typography variant="h6">Timeseries</Typography>
              </Toolbar>
            </AppBar>
            <TimeseriesList
              treeNodeId={selectedNodeId}
              setSelectedTimeseriesId={setSelectedTimeseriesId}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TimeseriesDetailComp timeseriesId={selectedTimeseriesId} />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};
