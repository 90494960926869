import { useState, useEffect, useContext } from "react";
import { pairedRoot } from "../services/api";
import { userActions } from "../reducers/userReducer.js";
import { UserContext } from "../providers/UserProvider";

const axios = require("axios");

export const useGetStationOverflowSpillways = (feature) => {
  const { state, dispatch } = useContext(UserContext);
  const [overflowSpillways, setOverflowSpillways] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { token } = state.user;

  const updateOverflowSpillways = (data) => {
    let tmpArray = [...overflowSpillways]
    if (data.length === tmpArray.length) {
      tmpArray.forEach((obj, index) => {
        obj.discharge_estimate = data[index].result_value
      })
      setOverflowSpillways(tmpArray)
    }
  }

  const clearOverflowSpillwayEstimates = () => {
    let tmpArray = [...overflowSpillways]
    tmpArray.forEach((obj) => {
      obj.discharge_estimate = null
    })
    setOverflowSpillways(tmpArray)
  }

  const getSpillwayDischargeEstimatesPayload = (poolElevation) => {
    return overflowSpillways.map((obj) => {
      return {
        "location_code": obj.location_code,
        "y_parameter_code": obj.y_parameter_code,
        "x_value": poolElevation,
      }
    })
  }

  const fetchSpillwayDischargeEstimates = async (poolElevation) => {
    setError(null)
    setLoading(true);
    try {
      const res = await axios.post(pairedRoot + "/paired_lookup/",
        getSpillwayDischargeEstimatesPayload(poolElevation),
        {
          headers: token && {
            Authorization: `Bearer ${token.access}`,
          },
        });

      let { data } = res;
      console.log(data)
      updateOverflowSpillways(data)
      setLoading(false);
    } catch (err) {
      if (err.response.status === 401) {
        window.localStorage.removeItem("afs_token");
        window.localStorage.removeItem("afs_user");
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
        setError("Unauthorized");
        setLoading(false);
      } else if (err.response.status === 403) {
        setError(
          "You do not have the appropriate permissions to view / use this resource."
        );
        setLoading(false);
      } else {
        console.log(err);
        setError(err.response.data.detail);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const formatOverflowSpillways = (spillwayArray) => {
      let tmpArray = spillwayArray.map((obj) => {
        return {
          paired_data: obj,
          name: "Spillway Discharge",
          location_code: feature.properties.code,
          y_parameter_code: "RQIP",
          discharge_estimate: null
        }
      })
      return tmpArray
    }
    const fetchStationOverflowSpillways = async () => {
      setLoading(true);
      try {
        const res = await axios.get(pairedRoot + "/paired/", {
          params: {
            location__code: feature.properties.code,
            y_parameter__code: "RQIP",
            ref_parameter_null: true
          },
          headers: token && {
            Authorization: `Bearer ${token.access}`,
          },
        });

        let { data } = res;
        setOverflowSpillways(formatOverflowSpillways(data))
        setLoading(false);
      } catch (err) {
        if (err.response.status === 401) {
          window.localStorage.removeItem("afs_token");
          window.localStorage.removeItem("afs_user");
          dispatch(userActions.setToken(null));
          dispatch(userActions.setMe(null));
          setError("Unauthorized");
          setLoading(false);
        } else if (err.response.status === 403) {
          setError(
            "You do not have the appropriate permissions to view / use this resource."
          );
          setLoading(false);
        } else {
          console.log(err);
          setError(err.message);
          setLoading(false);
        }
      }
    };

    fetchStationOverflowSpillways();
  },
    [feature, dispatch, token]
  );

  return { overflowSpillways, fetchSpillwayDischargeEstimates, clearOverflowSpillwayEstimates, loading, error };
};
